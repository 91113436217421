import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const getRandomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`;

const TeamGameTimelineComponent = ({ selectedGames, playerPositions, matchData }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (!selectedGames.length || !Object.keys(playerPositions).length) return;

    // Get all times from playerPositions across selected games
    const allTimes = selectedGames.flatMap(gameId =>
      (playerPositions[gameId] || []).map(p => p.time)
    );
    if (!allTimes.length) return;

    const minTime = Math.min(...allTimes);
    const maxTime = Math.max(...allTimes);

    // Generate a continuous timeline for every second from minTime to maxTime
    const allTimestamps = [];
    for (let t = minTime; t <= maxTime; t++) {
      allTimestamps.push(t);
    }

    const datasets = [];

    selectedGames.forEach((gameId) => {
      const gameData = playerPositions[gameId] || [];

      // Group data by summonerName
      const playerGroups = gameData.reduce((acc, p) => {
        if (!acc[p.summonerName]) acc[p.summonerName] = [];
        acc[p.summonerName].push(p);
        return acc;
      }, {});

      Object.entries(playerGroups).forEach(([playerName, positions]) => {
        // Create a time series for every second
        const timeSeries = allTimestamps.map(time => {
          const point = positions.find(p => p.time === time);
          return point ?  -(point.x - point.y) / Math.sqrt(2) : null;
        });

        datasets.push({
          label: `${playerName} (${positions[0]?.championName || "Unknown"})`,
          data: timeSeries,
          borderColor: getRandomColor(),
          borderWidth: 2,
          pointRadius: 0,
          pointHoverRadius: 2,
          fill: false,
        });
      });
    });

    setChartData({
      labels: allTimestamps.map(t => {
        const minutes = Math.floor(t / 60);
        const seconds = String(t % 60).padStart(2, "0");
        return `${minutes}:${seconds}`;
      }),
      datasets,
    });
  }, [selectedGames, playerPositions]);

  return (
    <div style={{ width: "100%", margin: "20px 0" }}>
      {chartData ? (
        <Line
          data={chartData}
          options={{
            plugins: {
              legend: {
                labels: {
                  color: "white",
                },
              },
            },
            scales: {
              y: {
                suggestedMin: -8000,
                suggestedMax: 8000,
                ticks: {
                  callback: function (value) {
                    if (value === 8000) return "Top";
                    if (value === 0) return "Mid";
                    if (value === -8000) return "Bot";
                    return "";
                  },
                },
              },
              x: {
                title: {
                  display: true,
                  text: "Time",
                },
                ticks: {
                  autoSkip: false, // show tick for every second
                  callback: function (value) {
                    // Only display a label if the timestamp is an exact minute
                    if (value % 60 === 0) {
                      // Assuming the game starts at 900 seconds (15 minutes)
                      return `${Math.floor(value / 60) + 15}:00`;
                    }
                    return "";
                  },
                },
              },
            },
          }}
        />
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
};

export default TeamGameTimelineComponent;
