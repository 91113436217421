import React, { useState, useEffect } from "react";
import axiosInstance from "../backend/axiosInstance";

const IssuesComponent = () => {
    const [dataLeague, setDataLeague] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    useEffect(() => {
        fetchLeagues();
    }, []);

    const fetchLeagues = () => {
        axiosInstance
            .get("issues/")
            .then((response) => {
                setDataLeague(response.data);
            })
            .catch((error) => {
                console.error("Error fetching team data:", error);
            });
    };


    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });

        setDataLeague((prev) =>
            [...prev].sort((a, b) => {
                if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
                if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
                return 0;
            })
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: "white" }}>
            <table border="1" style={{ color: "white", width: "80%", textAlign: "center" }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort("id")} style={{ cursor: "pointer" }}>
                            ID {sortConfig.key === "id" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("user")} style={{ cursor: "pointer" }}>
                            User {sortConfig.key === "user" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("date")} style={{ cursor: "pointer" }}>
                            Date {sortConfig.key === "date" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("url")} style={{ cursor: "pointer" }}>
                            Url {sortConfig.key === "url" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("title")} style={{ cursor: "pointer" }}>
                            Title {sortConfig.key === "title" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("descriptio")} style={{ cursor: "pointer" }}>
                            Description {sortConfig.key === "descriptio" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataLeague.map((league) => (
                        <tr key={league.id}>
                            <td>{league.id}</td>
                            <td style={{textAlign: "left", paddingLeft: "20px"}}>{league.user}</td>
                            <td style={{textAlign: "left", paddingLeft: "20px"}}>{league.date}</td>
                            <td style={{textAlign: "left", paddingLeft: "20px"}}>{league.url}</td>
                            <td style={{textAlign: "left", paddingLeft: "20px"}}>{league.title}</td>
                            <td style={{textAlign: "left", paddingLeft: "20px"}}>{league.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default IssuesComponent;
