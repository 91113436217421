import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import axiosInstance from './components/backend/axiosInstance';
import { useLocation } from "react-router-dom"; 

function Footbar({ loggedIn }) {
    const [showForm, setShowForm] = useState(false);
    const [input, setInput] = useState('');
    const location = useLocation(); 

    const handleButtonClick = () => {
        setShowForm(!showForm)
    }

    const handleSendButtonClick = () => {
        if (input.length > 0) {
            axiosInstance
                .post("issues/", {
                    user: localStorage.getItem("user") || localStorage.getItem("team"),
                    url: window.location.pathname,
                    title: document.title, // FIX: use document.title instead of location.title
                    description: input,
                })
                .then(() => {
                    setShowForm(false);
                    setInput(""); // Reset input after sending
                })
                .catch((error) => {
                    console.error("Error submitting issue:", error);
                });
        }
    };

    useEffect(() => {
        setShowForm(false);
    }, [location.pathname, document.title]);

    return (
        <div className="footbar" id="footbar" style={{color: 'white', fontSize: "smaller", textAlign: "center", position: 'relative'}}>
            SanchiDataPortal isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties. 
            <br></br>
            Riot Games, and all associated properties are trademarks or registered trademarks of Riot Games, Inc

            {loggedIn && (
                <div>
                    <button onClick={handleButtonClick}>Report</button>
                </div>  
            )}
            {showForm && (
                <div
                    style={{
                    position: "absolute",
                    backgroundColor: "white",
                    top: '-30px',
                    color: 'black',
                    padding: "5px",
                    borderRadius: "10px",
                    border: "1px solid #ddd",
                    display: 'flex',
                    flexDirection: "column",
                    width: "100%",
                    zIndex: 10,
                    }}>
                    <div style={{display: 'flex'}}>
                        {document.title} {window.location.pathname} {localStorage.getItem("user")}
                    </div>
                    <div style={{display: 'flex'}}>
                        <input onChange={(e) => setInput(e.target.value)} style={{width: "90%"}} placeholder="What is the issue?" maxLength={190} ></input>
                        <button onClick={handleSendButtonClick}>Send</button>
                    </div>

                </div>
            )}
        </div>
  );
}

export default Footbar;
