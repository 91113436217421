import React, { useState, useEffect } from "react";
import axiosInstance from "./backend/axiosInstance";

const LeagueComponent = () => {
    const [dataLeague, setDataLeague] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

    useEffect(() => {
        fetchLeagues();
    }, []);

    const fetchLeagues = () => {
        axiosInstance
            .get("league/")
            .then((response) => {
                setDataLeague(response.data);
            })
            .catch((error) => {
                console.error("Error fetching team data:", error);
            });
    };

    const updateLeague = (id, updatedData) => {
        axiosInstance
            .post("league/", { id, ...updatedData })
            .catch((error) => {
                console.error("Error updating league:", error);
            });
    };

    const handleTagChange = (id, newTag) => {
        setDataLeague((prev) =>
            prev.map((league) =>
                league.id === id ? { ...league, leagueTag: newTag } : league
            )
        );

        updateLeague(id, { leagueTag: newTag });
    };

    const handleActiveToggle = (id, currentActive) => {
        const newActive = !currentActive;
        setDataLeague((prev) =>
            prev.map((league) =>
                league.id === id ? { ...league, active: newActive } : league
            )
        );

        updateLeague(id, { active: newActive });
    };

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });

        setDataLeague((prev) =>
            [...prev].sort((a, b) => {
                if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
                if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
                return 0;
            })
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: "white" }}>
            <table border="1" style={{ color: "white", width: "80%", textAlign: "center" }}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort("id")} style={{ cursor: "pointer" }}>
                            ID {sortConfig.key === "id" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("leagueName")} style={{ cursor: "pointer" }}>
                            League Name {sortConfig.key === "leagueName" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("leagueTag")} style={{ cursor: "pointer" }}>
                            League Tag {sortConfig.key === "leagueTag" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("active")} style={{ cursor: "pointer" }}>
                            Active {sortConfig.key === "active" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataLeague.map((league) => (
                        <tr key={league.id}>
                            <td>{league.id}</td>
                            <td style={{textAlign: "left", paddingLeft: "20px"}}>{league.leagueName}</td>
                            <td>
                                <input
                                    type="text"
                                    value={league.leagueTag}
                                    onChange={(e) => handleTagChange(league.id, e.target.value)}
                                />
                            </td>
                            <td>
                                <button onClick={() => handleActiveToggle(league.id, league.active)}>
                                    {league.active ? "✅ Active" : "❌ Inactive"}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default LeagueComponent;
