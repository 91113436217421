import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../backend/axiosInstance";
import { useSeason } from "../SeasonContext";
import TeamGameTimelineComponent from "../TeamGameTimelineComponent";


const TeamPositionNahuComponent = () => {
  const { currentSeasonTime } = useSeason();
  const { teamName } = useParams();

  document.title = "Player Position";

  const [matchData, setMatchData] = useState([]);
  const [playerPositions, setPlayerPositions] = useState({});
  const [selectedGamesForChart, setSelectedGamesForChart] = useState([]);

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map((date) => date.trim());
    axiosInstance
      .get(
        `parsed_official_games/?start=${start}&end=${end}&team=${teamName}`
      )
      .then((response) => {
        const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setMatchData(sortedMatchData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [currentSeasonTime, teamName]);

  useEffect(() => {
    if (matchData.length > 0) {
      const fetchPositions = async () => {
        const positionRequests = matchData.map((game) =>
          axiosInstance.get(`playerposition/?gameId=${game.gameId}&team=${teamName}&start=${900}`).then((res) => ({
            gameId: game.gameId,
            data: res.data,
          }))
        );

        try {
          const results = await Promise.all(positionRequests);
          const positionsMap = results.reduce((acc, { gameId, data }) => {
            acc[gameId] = data;
            return acc;
          }, {});
          setPlayerPositions(positionsMap);
        } catch (error) {
          console.error("Error fetching player positions:", error);
        }
      };

      fetchPositions();
    }
  }, [matchData]);

  const toggleGameSelection = (gameId) => {
      setSelectedGamesForChart([gameId]);
  };

  return (
    <div style={{ display: "flex", color: "white" }}>
      {/* Left Section - Single Chart */}
      <div style={{ width: "70%" }}>
      <TeamGameTimelineComponent
        selectedGames={selectedGamesForChart}
        playerPositions={playerPositions}
        matchData={matchData} // Pass match data for correct team names in legend
      />
      </div>

      <div style={{ width: "30%", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
          <h3>Games to Display</h3>
          {matchData.map((game) => (
            <label key={game.gameId} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
              <input
                type="checkbox"
                checked={selectedGamesForChart.includes(game.gameId)}
                onChange={() => toggleGameSelection(game.gameId)}
              />
              <span style={{ marginLeft: "8px", color: "blue" }}>{game.team1}</span>
              <span style={{ marginLeft: "8px", color: "white" }}>vs</span>
              <span style={{ marginLeft: "8px", color: "red" }}>{game.team2}</span>

            </label>
          ))}
        </div>

      </div>
    </div>
  );
};

export default TeamPositionNahuComponent;
