import React, { useEffect, useState } from 'react';
import { useSeason } from '../../SeasonContext';
import LoadingComponent from '../../base/LoadingComponent';
import axiosInstance from '../../backend/axiosInstance';
import Slider from 'rc-slider';
import mapPhotsDictionary from '../../data/MapData';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';
import { TeamIcons } from '../../data/TeamIcons';
import { championPhotos } from '../../data/ChampionsIcon';


const PlayerTeleportComponent = ({ name }) => {
  const { currentSeasonTime } = useSeason();
  const [timeRange, setTimeRange] = useState([0, 60]);
  const [selectedTeam, setSelectedTeam] = useState('any');
  const [selectedWard, setSelectedWard] = useState('any');
  document.title = "Teleport " + name;
  const encodedPlayerName = encodeURIComponent(name);
  const [teleportData, setTeleportData] = useState([]);

  const [playerPositionData, setPlayerPositionData] = useState([]);


  const [matchData, setMatchData] = useState([]);
  const [filteredTeleportData, setFilteredTeleportData] = useState([]);


  const [hoveredTeleport, setHoveredTeleport] = useState(null); // Track hovered ward

  const minX = -120;
  const minY = -120;
  const maxX = 14970;
  const maxY = 14980;




  const captureScreenshot = (table) => {
      const element = document.getElementById(table);

      html2canvas(element)
          .then((canvas) => {
              const image = canvas.toDataURL('image/png');
              const link = document.createElement('a');
              link.download = 'screenshot.png';
              link.href = image;
              link.click();
          })
          .catch(err => {
              console.error('Error capturing screenshot', err);
          });
  };


  const translateCoordinates = (x, y) => {
    const newX = ((x - minX) / (maxX - minX)) * 500;
    const newY = ((maxY - y) / (maxY - minY)) * 500;
    return { newX, newY };
  };


  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());

    axiosInstance.get(`player/teleport/?name=${encodedPlayerName}&start=${start}&end=${end}`)
      .then((response) => {
        setTeleportData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }, [currentSeasonTime, name]);

  useEffect(() => {
    let filteredData = teleportData

    filteredData = filteredData.filter(ward => ward.startTime >= timeRange[0] * 60 && ward.startTime <= timeRange[1] * 60);

    if (selectedTeam !== 'any') {
      filteredData = filteredData.filter(ward => ward.side === selectedTeam);
    }

    setFilteredTeleportData(filteredData);
  }, [teleportData, selectedTeam, selectedWard, timeRange]);


  const handleTimeRangeChange = (newTimeRange) => {
    setTimeRange(newTimeRange);
  };

  const handleHover = (ward) => {
    fetchData(ward.gameId, ward.startTime)
    setHoveredTeleport(ward); // Set the hovered ward
  };

  const handleMouseLeave = () => {
    setHoveredTeleport(null); // Reset the hovered ward
    setPlayerPositionData([])
  };

  const fetchData = (gameId, time) => {
    axiosInstance.get(`playerposition/?gameId=${gameId}&time=${time}`)
    .then((response) => {
      setPlayerPositionData(response.data);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });

    axiosInstance.get(`parsed_official_games/?gameId=${gameId}`)
      .then((response) => {
        setMatchData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  }

  return (
    <div>
      <div>
        {teleportData? (
          <>
            <div style={{ width: "100%", color: "white", textAlign: "center", alignItems: "center" }}>
              <h1>TELEPORT MAP</h1>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: "100%", justifyContent: 'space-evenly' }}>
                <div>
                  <label htmlFor="teamSelect">Select side:</label>
                  <select id="teamSelect" value={selectedTeam} onChange={e => setSelectedTeam(e.target.value)}>
                    <option value="any">Any</option>
                    <option value="blue">Blue</option>
                    <option value="red">Red</option>
                  </select>
                </div>

              </div>
              <div style={{ width: "100%", display:'flex', justifyContent: "center", paddingBottom: "20px"}}>
                <div style={{ width: "80%"}}>
                  <span>{timeRange[0]} - {timeRange[1]}</span>
                  <Slider
                    range
                    min={0}
                    max={60}
                    step={1}
                    value={timeRange}
                    onChange={handleTimeRangeChange}
                    marks={{
                      0: "0",
                      15: "15",
                      30: "30",
                      45: "45",
                      60: "60",
                    }}
                    style={{
                      color: 'white',
                      width: '100%',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      background: 'darkblue',
                      border: 'none',
                      borderRadius: '5px',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    }}
                    />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <div id="heatmap-container-2" style={{ position: 'relative' }}>
                          <img style={{ position: 'relative' }} src={mapPhotsDictionary["SummonersRift"]} alt="" />
                          {filteredTeleportData && filteredTeleportData.map((tp, index) => {
                            const start = translateCoordinates(tp.positions[0].x, tp.positions[0].y);
                            const end = translateCoordinates(tp.positions[1].x, tp.positions[1].y);
                            const angle = Math.atan2(end.newY - start.newY, end.newX - start.newX) * 180 / Math.PI;
                            const distance = Math.sqrt((end.newX - start.newX) ** 2 + (end.newY - start.newY) ** 2);

                              return (
                                <div key={index}>
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: `${start.newY}px`,
                                      left: `${start.newX}px`,
                                      width: `${distance}px`,
                                      height: '2px',
                                      backgroundColor: hoveredTeleport === tp ? 'yellow' : tp.positions[0].side === 'blue' ? 'blue' : 'red',
                                      transform: `rotate(${angle}deg)`,
                                      transformOrigin: '0 50%',
                                      zIndex: hoveredTeleport === tp ? 2 : 1,
                                      transition: 'opacity 0.3s',
                                      opacity: hoveredTeleport && hoveredTeleport !== tp ? 0.2 : 1,
                                    }}
                                    onMouseEnter={() => handleHover(tp)}
                                    onMouseLeave={handleMouseLeave}
                                  />

                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: `${end.newY - 5}px`,
                                      left: `${end.newX-10}px`,
                                      width: '0',
                                      height: '0',
                                      borderLeft: '10px solid transparent',
                                      borderRight: '10px solid transparent',
                                      borderBottom: `15px solid ${ hoveredTeleport === tp ? 'yellow' :tp.positions[0].side === 'blue' ? 'blue' : 'red'}`,
                                      transform: `rotate(${angle + 90}deg)`,
                                      zIndex: hoveredTeleport === tp ? 3 : 2,
                                      opacity: hoveredTeleport && hoveredTeleport !== tp ? 0.2 : 1,

                                    }}
                                  />
                                </div>
                              );
                          })}
                          {playerPositionData && (
                            <div
                                key='timerr'
                                style={{
                                position: 'absolute',
                                top:  0,
                                left: "45%",
                                color: 'white'
                                }}>
                                {Math.floor(30 / 60)}:{String(30 % 60).padStart(2, '0')}
                            </div>
                          )}
                          {playerPositionData && (
                              <div
                                  key='timerr'
                                  style={{
                                  position: 'absolute',
                                  top:  0,
                                  left: "20%",
                                  color: 'blue',
                                  fontWeight: matchData.team1,

                                }}>
                                  <img src={TeamIcons[matchData.team1]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                                  {matchData.team1}
                              </div>
                          )}
                          {playerPositionData && (
                              <div
                                  key='timerr'
                                  style={{
                                  position: 'absolute',
                                  top:  0,
                                  left: "70%",
                                  color: 'red',
                                  fontWeight: matchData.team2,
                                  }}>
                                  <img src={TeamIcons[matchData.team2]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                                  {matchData.team2}
                              </div>
                          )}
                          {playerPositionData && playerPositionData.map((position, index) => {
                              const { newX, newY } = translateCoordinates(position.x, position.y);
                              return (
                                  <div
                                  key={`player--${index}-t`}
                                  style={{
                                      position: 'absolute',
                                      top: `${newY - 13}px`,
                                      left: `${newX - 13}px`,
                                      width: '26px',
                                      height: '26px',
                                      backgroundColor: position.side == "blue" ? "blue":"red",

                                      borderRadius: '50%',
                                  }}
                                  >

                                      <div
                                          key={`player--${index}`}
                                          style={{
                                              position: 'absolute',
                                              top: `2px`,
                                              left: `2px`,
                                              width: '22px',
                                              height: '22px',
                                              backgroundColor: position.side,
                                              borderRadius: '50%',
                                          }}
                                          >

                                          <img
                                              src={championPhotos[position.championName]}
                                              alt=""
                                              style={{
                                                  width: '20px',
                                                  height: '20px',
                                                  position: 'absolute',
                                                  borderRadius: '50%',
                                                  top: '1px',
                                                  left: '1px',
                                              }}
                                              />
                                      </div>
                                  </div>
                              );
                              }
                          )}

                      </div>
                  <button style={{ width: "100%" }} className='button-top-page' onClick={() => captureScreenshot('heatmap-container-2')}>ScreenShot</button>
                </div>
                <div style={{ overflowY: 'scroll', height: '500px', width: '40%' }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Timer</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Player</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTeleportData.sort((a,b)=> a.startTime - b.startTime).map((ward, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                          onMouseEnter={() => handleHover(ward)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <td style={{ padding: '10px' }}>
                            {Math.floor(ward.startTime / 60)}:{String(ward.startTime % 60).padStart(2, '0')}
                          </td>
                          <td style={{ padding: '10px' }}>{ward.summonerName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  );
};

export default PlayerTeleportComponent;
