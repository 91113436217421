import React, { useEffect, useState } from 'react';
import { useSeason } from './SeasonContext';
import HeatmapComponent from './WardHeatmap';
import WardMapComponent from './WardMap';
import LoadingComponent from './base/LoadingComponent';
import axiosInstance from './backend/axiosInstance';
import OracleLensComponent from './player/ward/OracleLens';
import Slider from 'rc-slider';

const PlayerHeatmapComponent = ({ name, gameType }) => {
  const { currentSeasonTime } = useSeason();
  const [timeRange, setTimeRange] = useState([0, 60]);
  const [selectedTeam, setSelectedTeam] = useState('any');
  const [selectedWard, setSelectedWard] = useState('any');
  document.title = "Heatmap " + name;
  const encodedPlayerName = encodeURIComponent(name);
  const [wardData, setWardData] = useState([]);
  const [oracleLensData, setOracleLensData] = useState([]);

  const [filteredWardData, setFilteredWardData] = useState([]);
  const [filteredOracleLensData, setFilteredOracleLensData] = useState([]);


  const [selectedPlayers, setSelectedPlayers] = useState([]); // Tracks selected players

  const [hoveredWard, setHoveredWard] = useState(null); // Track hovered ward
  const [hoveredOracleLens, setHoveredOracleLens] = useState(null); // Track hovered ward

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    let apiUrl;
    if (gameType === 'Officials') {
      apiUrl = `playerwards_official/?name=${encodedPlayerName}&start=${start}&end=${end}`;
    } else {
      apiUrl = `playerwards/?name=${encodedPlayerName}`;
    }
    axiosInstance.get(apiUrl)
      .then((response) => {
        setWardData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      let apiUrl2;
      if (gameType === 'Officials') {
        apiUrl2 = `player_oracle_lens_official/?name=${encodedPlayerName}&start=${start}&end=${end}`;
      } else {
        apiUrl2 = `player_oracle_lens_scrims/?name=${encodedPlayerName}`;
      }
      axiosInstance.get(apiUrl2)
        .then((response) => {
          setOracleLensData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
  }, [currentSeasonTime, name, gameType]);

  useEffect(() => {
    let filteredData = wardData;
    let filteredOracleData = oracleLensData;

    if (selectedPlayers.length > 0) {
      filteredData = filteredData.filter(ward => selectedPlayers.includes(ward.summonerName));
    }

    filteredData = filteredData.filter(ward => ward.placeTime >= timeRange[0] * 60 && ward.placeTime <= timeRange[1] * 60);

    filteredOracleData = filteredOracleData.filter(ward => ward.oracleTime >= timeRange[0] * 60 && ward.oracleTime <= timeRange[1] * 60);



    if (selectedWard !== 'any') {
      filteredData = filteredData.filter(ward =>
        selectedWard === 'yellowTrinket' ? ['yellowTrinket', 'sight'].includes(ward.type) : ward.type === selectedWard
      );
    }

    if (selectedTeam !== 'any') {
      filteredData = filteredData.filter(ward => ward.side === selectedTeam);
    }

    if (selectedTeam !== 'any') {
      filteredOracleData = filteredOracleData.map(row => ({
        ...row,
        positions: row.positions.filter(position => position.side === selectedTeam)
      })).filter(row => row.positions.length > 0);
    }
    setFilteredOracleLensData(filteredOracleData)
    setFilteredWardData(filteredData);
  }, [wardData, selectedPlayers, selectedTeam, selectedWard, timeRange, oracleLensData]);


  const handleTimeRangeChange = (newTimeRange) => {
    setTimeRange(newTimeRange);
  };

  const handleHover = (ward) => {
    setHoveredWard(ward); // Set the hovered ward
  };

  const handleMouseLeave = () => {
    setHoveredWard(null); // Reset the hovered ward
  };


  const handleHover2 = (ward) => {
    setHoveredOracleLens(ward); // Set the hovered ward
  };

  const handleMouseLeave2 = () => {
    setHoveredOracleLens(null); // Reset the hovered ward
  };

  return (
    <div>
      <div>
        {wardData ? (
          <>
            <div style={{ width: "100%", color: "white", textAlign: "center", alignItems: "center" }}>
              <br></br>
              <h1>WARDS HEATMAP</h1>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                </div>
                <HeatmapComponent wardData={wardData} />
                <div>

                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>

      <div>
        {wardData ? (
          <>
            <div style={{ width: "100%", color: "white", textAlign: "center", alignItems: "center" }}>
              <h1>WARDS MAP</h1>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: "100%", justifyContent: 'space-evenly' }}>
                <div>
                  <label htmlFor="teamSelect">Select side:</label>
                  <select id="teamSelect" value={selectedTeam} onChange={e => setSelectedTeam(e.target.value)}>
                    <option value="any">Any</option>
                    <option value="blue">Blue</option>
                    <option value="red">Red</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="teamSelect1">Ward type:</label>
                  <select id="teamSelect1" value={selectedWard} onChange={e => setSelectedWard(e.target.value)}>
                    <option value="any">Any</option>
                    <option value="control">Control</option>
                    <option value="yellowTrinket">Yellow</option>
                    <option value="blueTrinket">Blue</option>
                  </select>
                </div>
              </div>
              <div style={{ width: "100%", display:'flex', justifyContent: "center", paddingBottom: "20px"}}>
                <div style={{ width: "80%"}}>
                  <span>{timeRange[0]} - {timeRange[1]}</span>
                  <Slider
                    range
                    min={0}
                    max={60}
                    step={1}
                    value={timeRange}
                    onChange={handleTimeRangeChange}
                    marks={{
                      0: "0",
                      15: "15",
                      30: "30",
                      45: "45",
                      60: "60",
                    }}
                    style={{
                      color: 'white',
                      width: '100%',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      background: 'darkblue',
                      border: 'none',
                      borderRadius: '5px',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    }}
                    />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <WardMapComponent wardData={filteredWardData} hoveredWard={hoveredWard} />
                <div style={{ overflowY: 'scroll', height: '500px', width: '40%' }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Timer</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Player</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Ward Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredWardData.sort((a,b)=> a.placeTime - b.placeTime).map((ward, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                          onMouseEnter={() => handleHover(ward)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <td style={{ padding: '10px' }}>
                            {Math.floor(ward.placeTime / 60)}:{String(ward.placeTime % 60).padStart(2, '0')}
                          </td>
                          <td style={{ padding: '10px' }}>{ward.summonerName}</td>
                          <td style={{ padding: '10px' }}>{ward.type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>

      <div>
        {oracleLensData ? (
          <>
            <div style={{ width: "100%", color: "white", textAlign: "center", alignItems: "center" }}>
              <h1>Oracle Lens</h1>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: "100%", justifyContent: 'space-evenly' }}>
                <div>
                  <label htmlFor="teamSelect">Select side:</label>
                  <select id="teamSelect" value={selectedTeam} onChange={e => setSelectedTeam(e.target.value)}>
                    <option value="any">Any</option>
                    <option value="blue">Blue</option>
                    <option value="red">Red</option>
                  </select>
                </div>
              </div>
              <div style={{ width: "100%", display:'flex', justifyContent: "center", paddingBottom: "20px"}}>
                <div style={{ width: "80%"}}>
                  <span>{timeRange[0]} - {timeRange[1]}</span>
                  <Slider
                    range
                    min={0}
                    max={60}
                    step={1}
                    value={timeRange}
                    onChange={handleTimeRangeChange}
                    marks={{
                      0: "0",
                      15: "15",
                      30: "30",
                      45: "45",
                      60: "60",
                    }}
                    style={{
                      color: 'white',
                      width: '100%',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      background: 'darkblue',
                      border: 'none',
                      borderRadius: '5px',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    }}
                    />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <OracleLensComponent oracleLensData={filteredOracleLensData} hoveredOracleLens={hoveredOracleLens}/>
                <div style={{ overflowY: 'scroll', height: '500px', width: '40%' }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Timer</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Player</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredOracleLensData.sort((a,b)=> a.oracleTime - b.oracleTime).map((ward, index) => (
                        <tr
                          key={index}
                          style={{ borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                          onMouseEnter={() => handleHover2(ward)}
                          onMouseLeave={handleMouseLeave2}
                        >
                          <td style={{ padding: '10px' }}>
                            {Math.floor(ward.oracleTime / 60)}:{String(ward.oracleTime % 60).padStart(2, '0')}
                          </td>
                          <td style={{ padding: '10px' }}>{ward.summonerName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  );
};

export default PlayerHeatmapComponent;
