import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSeason } from '../SeasonContext';
import axiosInstance from '../backend/axiosInstance';
import { TeamIcons } from '../data/TeamIcons';
import { championData, championPhotos } from "../data/ChampionsIcon";
import roleIcon from '../data/Role';
import { getRedGreenCellno0 } from '../base/colorUtils';
import Slider from 'rc-slider';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
  
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  

function renderChampions(game, firstChampion, secondChampion, thirdChampion, fourthChampion, fifthChampion, isBlueTeam) {
    const teamOrderDict = isBlueTeam ? {
        [firstChampion]: "BP1",
        [secondChampion]: "BP2",
        [thirdChampion]: "BP3",
        [fourthChampion]: "BP4",
        [fifthChampion]: "BP5",
    } : {
        [firstChampion]: "RP1",
        [secondChampion]: "RP2",
        [thirdChampion]: "RP3",
        [fourthChampion]: "RP4",
        [fifthChampion]: "RP5",
    };

    return [0, 1, 2, 3, 4].map(index => {
        const championKey = teamOrderDict[index];
        return (
            <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: isBlueTeam ? '0 5px 0 0' : '0 0 0 5px' }}>
                <img src={roleIcon[index]} alt={""} style={{ width: "15px" }} />
                <img
                    src={championPhotos[championData[game[championKey]]]}
                    alt={""}
                    className="small-image"
                />
            </div>
        );
    });
}

const TeamProximityComponent = () => {
  const { currentSeasonTime } = useSeason();
  const { teamName } = useParams();

  document.title = "Proximity " + teamName;

  const [matchData, setMatchData] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const [playerPosition, setPlayerPosition] = useState([]); // New state for ward data
  const [selectedJungler, setSelectedJungler] = useState({ value: '', label: '' });
  const [playerName, setPlayerName] = useState([]);
  const [junglerProximity, setJunglerProximity] = useState([]);
  const [playerProximities, setPlayerProximities] = useState([]);
  const [timeRange, setTimeRange] = useState([0, 30]); // default: minutes 0-30
  const [selectedPlayerForChart, setSelectedPlayerForChart] = useState(null);

  const champToSummoner = {};


  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get('parsed_official_games/?start=' + start + "&end=" + end + '&team=' + teamName)
    .then((response) => {
      const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setMatchData(sortedMatchData);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
    }, [currentSeasonTime, teamName]);


  const goToPreviousGame = () => {
    if (currentIndex > 0) {
        handleGameSelect(currentIndex - 1);
    }
  };

    const goToNextGame = () => {
        if (currentIndex < matchData.length - 1) {
            handleGameSelect(currentIndex + 1);
        }
    };

  const handleGameSelect = (index) => {
    const gameData = matchData[index];
    setSelectedGame(gameData);
    setDropdownOpen(false);
    setCurrentIndex(index);
    fetchData(gameData.gameId, gameData);
};

const fetchData = (gameId, gameData) => {
    axiosInstance.get(`playerposition/?gameId=${gameId}`)
        .then(response => {
            setPlayerPosition(response.data);
            const playerNames = [...new Set(response.data.map(player => player.summonerName))];
            setPlayerName(playerNames);
            response.data.forEach(player => {
                champToSummoner[player.championName] = player.summonerName;
              });
            const teamOrderDict = gameData.team1 === teamName ? {
                [gameData.B1R]: "BP1",
                [gameData.B2R]: "BP2",
                [gameData.B3R]: "BP3",
                [gameData.B4R]: "BP4",
                [gameData.B5R]: "BP5",
            } : {
                [gameData.R1R]: "RP1",
                [gameData.R2R]: "RP2",
                [gameData.R3R]: "RP3",
                [gameData.R4R]: "RP4",
                [gameData.R5R]: "RP5",
            };

            const junglerChampion = championData[gameData[teamOrderDict[1]]];
            const jungler = response.data.find(player => player.championName === junglerChampion);

            if (jungler) {
                setSelectedJungler({ value: jungler.summonerName, label: jungler.summonerName });
                calculateProximities(jungler.summonerName, response.data); // initial calc
            }
        })
        .catch(error => {
            console.error('Error fetching player data:', error);
        });
};


    const calculateProximities = (junglerName, playerDataRaw) => {
        const playerNames = [...new Set(playerDataRaw.map(player => player.summonerName))];
        setPlayerName(playerNames);

        const junglerData = playerDataRaw
            .filter(player => player.summonerName === junglerName)
            .map(player => [player.time, player.x, player.y]);

        const playerData = playerDataRaw.map(player => [player.summonerName, player.time, player.x, player.y]);

        const calculateDistance = (x1, y1, x2, y2) => {
            return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        };

        const maxMinute = Math.floor(Math.max(...playerDataRaw.map(player => player.time)) / 60 + 1);
        const playerProximityData = playerNames.reduce((acc, name) => {
            acc[name] = Array(maxMinute).fill(0);
            return acc;
        }, {});

        junglerData.forEach(([j_time, j_x, j_y]) => {
            const minute = Math.floor(j_time / 60);
            playerData.forEach(([p_name, p_time, p_x, p_y]) => {
                if (Math.abs(j_time - p_time) === 0 && p_name !== junglerName) {
                    const distance = calculateDistance(j_x, j_y, p_x, p_y);
                    if (distance <= 2000) {
                        playerProximityData[p_name][minute] += 1;
                    }
                }
            });
        });

        Object.keys(playerProximityData).forEach(playerName => {
            playerProximityData[playerName] = playerProximityData[playerName].map(count => ((count / 60) * 100).toFixed(0));
        });

        setPlayerProximities(playerProximityData);
    };

    useEffect(() => {
        if (selectedJungler && playerPosition.length > 0) {
            calculateProximities(selectedJungler.value, playerPosition);
        }
    }, [selectedJungler]);
    
    const maxMinute = playerProximities && Object.keys(playerProximities).length > 0
    ? playerProximities[Object.keys(playerProximities)[0]].length - 1
    : 60;
  

  return (
    <div>
        <div className='filters' style={{ textAlign: 'center', marginBottom: '20px', color: "black" }}>
        <div style={{ marginTop: '10px' }}>
          <button
            className='button-top-page'
            onClick={goToPreviousGame}
            disabled={currentIndex === 0}
            style={{ marginRight: '15px', padding: '5px 10px' , width: "120px" }}
          >
            {"<-"}Previous
          </button>
        </div>
            <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                style={{
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    display: 'inline-block',
                    width: '700px',
                    height: '67px',
                    backgroundColor: 'white',
                    position: 'relative',
                }}
            >
                {selectedGame ?  (
                <div style={{
                    border: '1px solid #ccc',
                    position: 'absolute',
                    backgroundColor: 'white',
                    zIndex: 100,
                    width: '700px',
                    maxHeight: '400px',
                    overflowY: 'auto'
                }}>
                        <div
                            key={selectedGame.gameId}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderBottom: '1px solid #ccc',
                                padding: "10px"
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{width: "120px"}}>
                                    {selectedGame.leagueName} {selectedGame.patch}
                                </div>
                                <div style={{display: 'flex'}}>
                                    {renderChampions(selectedGame, selectedGame.B1R, selectedGame.B2R, selectedGame.B3R, selectedGame.B4R, selectedGame.B5R, true)}
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                        <img className="small-image" src={TeamIcons[selectedGame.team1]} alt={selectedGame.team1} style={{ width: '25px', marginRight: '5px' }} />
                                        {selectedGame.team1} vs {selectedGame.team2}
                                        <img className="small-image" src={TeamIcons[selectedGame.team2]} alt={selectedGame.team2} style={{ width: '25px', marginLeft: '5px' }} />
                                    </div>
                                    {renderChampions(selectedGame, selectedGame.R1R, selectedGame.R2R, selectedGame.R3R, selectedGame.R4R, selectedGame.R5R, false)}
                                </div>
                            </div>
                        </div>
                </div>
            ) : 'Select a Game'}

                {dropdownOpen && (
                    <div
                        style={{
                            border: '1px solid #ccc',
                            position: 'absolute',
                            backgroundColor: 'white',
                            zIndex: 100,
                            width: '700px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            top: '67px',
                        }}
                    >
                        {matchData.map((game, index) => {
                            return (
                                <div
                                    key={game.gameId}
                                    onClick={() => handleGameSelect(index)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px',
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #ccc',
                                        transition: 'background 0.3s',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.background = '#f0f0f0'}
                                    onMouseLeave={(e) => e.currentTarget.style.background = 'white'}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <div style={{ width: '120px', textAlign: 'center' }}>
                                            {game.leagueName} {game.patch}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.B1R, game.B2R, game.B3R, game.B4R, game.B5R, true)}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                                                <img className="small-image" src={TeamIcons[game.team1]} alt={`Team ${game.team1}`} style={{ width: '35px', marginRight: '10px' }} />
                                                <span style={{ fontWeight: 'bold' }}>{game.team1} vs {game.team2}</span>
                                                <img className="small-image" src={TeamIcons[game.team2]} alt={`Team ${game.team2}`} style={{ width: '35px', marginLeft: '10px' }} />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                            {renderChampions(game, game.R1R, game.R2R, game.R3R, game.R4R, game.R5R, false)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <div style={{ marginTop: '10px' }}>
                <button
                className='button-top-page'
                onClick={goToNextGame}
                disabled={currentIndex === matchData.length - 1}
                style={{ marginLeft: '15px', padding: '5px 10px', width: "120px" }}
                >
                    Next {"->"}
                </button>
            </div>
        </div>

        {playerPosition && playerProximities && playerPosition.length > 0 && playerName.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', color: 'white' }}>
                <div style={{ width: '100%', textAlign: 'center', display: "flex", justifyContent: "center" }}>
                    <div>

                    <h1>Player Proximity</h1>
                    <select
                        value={selectedJungler.value}
                        onChange={(e) => setSelectedJungler({ value: e.target.value, label: e.target.value })}
                        style={{ padding: '10px', backgroundColor: '#282c34', color: 'white', borderRadius: '5px', marginBottom: '20px' }}
                    >
                        {playerName.map((name) => (
                        <option key={name} value={name}>
                            {name}
                        </option>
                        ))}
                    </select>
                    </div>
                    <div style={{width: "50%", alignItems: 'center'}}>
                        <Slider
                            range
                            min={0}
                            max={maxMinute}
                            value={timeRange}
                            onChange={(value) => setTimeRange(value)}
                            allowCross={false}
                            trackStyle={[{ backgroundColor: '#61dafb' }]}
                            handleStyle={[
                                { borderColor: '#61dafb', backgroundColor: '#282c34' },
                                { borderColor: '#61dafb', backgroundColor: '#282c34' }
                            ]}
                            railStyle={{ backgroundColor: '#888' }}
                        />
                    </div>

            </div>
            {selectedPlayerForChart && playerProximities[selectedPlayerForChart] && (
                <div style={{ width: '90%', maxWidth: '1000px', margin: '20px auto' }}>
                    <h3 style={{ textAlign: 'center' }}>
                    Proximity of <span style={{ color: '#61dafb' }}>{selectedPlayerForChart}</span> to <span style={{ color: '#ffa726' }}>{selectedJungler.value}</span>
                    </h3>
                    <Bar
                    data={{
                        labels: playerProximities[selectedPlayerForChart]
                        .map((_, i) => i)
                        .filter(i => i >= timeRange[0] && i <= timeRange[1]),
                        datasets: [
                        {
                            label: 'Proximity %',
                            data: playerProximities[selectedPlayerForChart]
                            .map(v => parseInt(v))
                            .filter((_, i) => i >= timeRange[0] && i <= timeRange[1]),
                            backgroundColor: playerProximities[selectedPlayerForChart]
                                                .map(v => parseInt(v))
                                                .filter((_, i) => i >= timeRange[0] && i <= timeRange[1])
                                                .map(v => getRedGreenCellno0(100 - v, 0, 100)),
                        },
                        ],
                    }}
                    options={{
                        responsive: true,
                        scales: {
                        y: {
                            beginAtZero: true,
                            max: 100,
                        },
                        },
                        plugins: {
                        legend: { display: false },
                        tooltip: {
                            callbacks: {
                            label: ctx => `Proximity: ${ctx.parsed.y}%`,
                            },
                        },
                        },
                    }}
                    />
                </div>
                )}

            <div style={{ margin: '20px auto', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px' }}>
            {playerName
                .filter(name => name !== selectedJungler.value)
                .map(name => (
                <button
                    key={name}
                    onClick={() => setSelectedPlayerForChart(name)}
                    style={{
                    padding: '8px 12px',
                    backgroundColor: name === selectedPlayerForChart ? '#61dafb' : '#282c34',
                    color: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    cursor: 'pointer'
                    }}
                >
                    {name}
                </button>
                ))}
            </div>

                <table style={{ borderCollapse: 'collapse', marginBottom: '20px' }}>
                <thead>
                    <tr>
                    <th style={{ padding: '10px', border: '1px solid #ddd', backgroundColor: '#444' }}>Time</th>
                    {[0, 1, 2, 3, 4].map(index => {
                        const teamOrderDict = selectedGame.team1 === teamName ? {
                        [selectedGame.B1R]: "BP1",
                        [selectedGame.B2R]: "BP2",
                        [selectedGame.B3R]: "BP3",
                        [selectedGame.B4R]: "BP4",
                        [selectedGame.B5R]: "BP5",
                        } : {
                        [selectedGame.R1R]: "RP1",
                        [selectedGame.R2R]: "RP2",
                        [selectedGame.R3R]: "RP3",
                        [selectedGame.R4R]: "RP4",
                        [selectedGame.R5R]: "RP5",
                        };
                        const championKey = teamOrderDict[index];
                        return (
                        <th key={index} style={{ padding: '10px', border: '1px solid #ddd', color: playerPosition.find(p => p.championName === championData[selectedGame[championKey]])?.summonerName === selectedJungler.value ?"black": "white", backgroundColor: playerPosition.find(p => p.championName === championData[selectedGame[championKey]])?.summonerName === selectedJungler.value ? "white": '#444' }}>
                            <img
                            src={championPhotos[championData[selectedGame[championKey]]] || '/default-champion.png'}
                            alt={championKey}
                            className="small-image"
                            style={{ width: '40px', height: '40px' }}
                            />
                            {playerPosition.find(p => p.championName === championData[selectedGame[championKey]])?.summonerName || ''}

                        </th>
                        );
                    })}
                    {[0, 1, 2, 3, 4].map(index => {
                        const teamOrderDict = selectedGame.team1 != teamName ? {
                        [selectedGame.B1R]: "BP1",
                        [selectedGame.B2R]: "BP2",
                        [selectedGame.B3R]: "BP3",
                        [selectedGame.B4R]: "BP4",
                        [selectedGame.B5R]: "BP5",
                        } : {
                        [selectedGame.R1R]: "RP1",
                        [selectedGame.R2R]: "RP2",
                        [selectedGame.R3R]: "RP3",
                        [selectedGame.R4R]: "RP4",
                        [selectedGame.R5R]: "RP5",
                        };
                        const championKey = teamOrderDict[index];
                        return (
                        <th key={index} style={{ padding: '10px', border: '1px solid #ddd', color: playerPosition.find(p => p.championName === championData[selectedGame[championKey]])?.summonerName === selectedJungler.value ?"black": "white", backgroundColor: playerPosition.find(p => p.championName === championData[selectedGame[championKey]])?.summonerName === selectedJungler.value ? "white": '#444' }}>
                            <img
                            src={championPhotos[championData[selectedGame[championKey]]]}
                            alt={championKey}
                            className="small-image"
                            style={{ width: '40px', height: '40px' }}
                            />
                            {playerPosition.find(p => p.championName === championData[selectedGame[championKey]])?.summonerName || ''}

                        </th>
                        );
                    })}
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: playerProximities[Object.keys(playerProximities)[0]].length }).map((_, minute) => (
                        <tr key={minute}>
                            <td style={{ padding: '10px', border: '1px solid #ddd', backgroundColor: '#222' }}>{minute}</td>
                            {Object.keys(playerProximities).map((playerName, index) => (
                                <td key={index} style={{ padding: '10px', color: 'black', border: '1px solid #ddd', backgroundColor: getRedGreenCellno0(100-playerProximities[playerName][minute], 0, 100) }}>
                                    {playerProximities[playerName][minute] !== undefined ? playerProximities[playerName][minute] : 'N/A'}%
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>

                </table>
            </div>
        )}

    </div>
  );
};

export default TeamProximityComponent;
