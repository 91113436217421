import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CustomDateInput from '../base/CustomInputCalendar';
import { useSeason } from '../SeasonContext';
import axiosInstance from '../backend/axiosInstance';
import LoadingComponent from '../base/LoadingComponent';
import { TeamIcons } from '../data/TeamIcons';
import { championData, championPhotos } from "../data/ChampionsIcon";
import CustomSelect from '../base/CustomSelect';
import CustomInput from '../base/CustomInput';
import roleIcon from '../data/Role';
import { handleROFLClick2 } from '../base/base';


function renderChampions(game, firstChampion, secondChampion, thirdChampion, fourthChampion, fifthChampion, isBlueTeam) {
  const teamOrderDict = isBlueTeam ? {
      [firstChampion]: "BP1",
      [secondChampion]: "BP2",
      [thirdChampion]: "BP3",
      [fourthChampion]: "BP4",
      [fifthChampion]: "BP5",
  } : {
      [firstChampion]: "RP1",
      [secondChampion]: "RP2",
      [thirdChampion]: "RP3",
      [fourthChampion]: "RP4",
      [fifthChampion]: "RP5",
  };

  return [0, 1, 2, 3, 4].map(index => {
      const championKey = teamOrderDict[index];
      return (
          <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: isBlueTeam ? '0 5px 0 0' : '0 0 0 5px' }}>
              <img src={roleIcon[index]} alt={""} style={{ width: "15px" }} />
              <img
                  src={championPhotos[championData[game[championKey]]]}
                  alt={""}
                  className="small-image"
              />
          </div>
      );
  });
}

const TeamObjectiveTimersComponent = () => {
  const { currentSeasonTime } = useSeason();
  const { teamName } = useParams();

  document.title = "Objective " + teamName;

  const [matchData, setMatchData] = useState([]);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30); // Subtract 30 days
    return date;
  });

  const [endDate, setEndDate] = useState(new Date());
  const [draftData, setDraftdata] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [selectedObjective, setSelectedObjective] = useState([]);
  const [filterObjective, setFilterObjective] = useState([]);
  const [objectiveOption, setObjectiveOption] = useState([]);

  const [selectedTaken, setSelectedTaken] = useState([]);
  const [filterTaken, setFilterTaken] = useState([]);

  const takenOptions = [
    { value: 'any', label: 'Any' },
    { value: 'taken', label: 'Taken' },
    { value: 'lost', label: 'Lost' },
  ];

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0);
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 59, 59, 999);
    setEndDate(selectedDate);
  };

  const generateObjectiveLabels = (matches) => {
    const objectiveCounters = {}; 
    return matches.map((match) => {
      const eventType = match.event.toLowerCase();
      const gameId = match.scrimId; // You can use scrimId or gameId as a unique identifier for each match

      if (!objectiveCounters[gameId]) {
        objectiveCounters[gameId] = {}; // Initialize a counter object for the game
      }

      if (!objectiveCounters[gameId][eventType]) {
        objectiveCounters[gameId][eventType] = 1; // Initialize counter for this specific objective in the game
      } else {
        objectiveCounters[gameId][eventType] += 1; // Increment the counter for this specific objective
      }

      return {
        ...match,
        eventLabel: `${match.event}${objectiveCounters[gameId][eventType]}`, // Append the count to the event
        
      };
    });
  };



  useEffect(() => {
    if (!startDate || !endDate) return;

    const fetchData = async () => {
        try {
            setIsLoading(true); // Prevent UI updates before data is ready
            const adjustedEndDate = new Date(endDate);
            adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

            // Fetch draft data first
            const response2 = await axiosInstance.get('parsed_official_games/', {
                params: {
                    start: formatDate(startDate),
                    end: formatDate(adjustedEndDate),
                    team: teamName,
                },
            });

            const newDraftData = response2.data.reduce((acc, obj) => {
                acc[obj.gameId] = obj;
                return acc;
            }, {});
            setDraftdata(newDraftData);

            // Fetch match data AFTER draft data is set
            const response = await axiosInstance.get('events/', {
                params: {
                    start: formatDate(startDate),
                    end: formatDate(adjustedEndDate),
                    team: teamName,
                    short: 1,
                },
            });

            const data = response.data.map((item) => {
                try {
                    if (item.Vod.includes('t=')) {
                        const fixedVod = item.Vod.replace(/&amp;/g, '&');
                        const [baseUrl, queryString] = fixedVod.split('?');
                        const params = new URLSearchParams(queryString);
                        let timestamp = params.get('t');

                        if (timestamp && !isNaN(timestamp) && typeof item.time === 'number') {
                            return { ...item, Vod: `${baseUrl}?t=${Number(timestamp) + item.time - 30}` };
                        }
                        return { ...item, Vod: baseUrl };
                    }
                } catch (error) {
                    console.error('Error parsing timestamp:', error, item.Vod);
                }
                return item;
            });

            const labeledMatches = generateObjectiveLabels(data);
            const uniqueObjectives = [...new Set(labeledMatches.map((item) => item.eventLabel))].sort();
            console.log(uniqueObjectives)
            setObjectiveOption(uniqueObjectives.map((obj) => ({ value: obj, label: obj })));
    
            setMatchData(labeledMatches);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false); // Only update UI once all data is ready
        }
    };

    fetchData();
}, [startDate, endDate, teamName]);



  const handleObjectiveChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterObjective(leagueValues);
    setSelectedObjective(selectedOptions);
  };

  const handleTakenChange = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    setFilterTaken(values);
    setSelectedTaken(selectedOptions);
  };

  const filteredMatches = matchData.filter(match =>
    (!filterObjective.length || filterObjective.includes(match.eventLabel)) &&
    (!filterTaken.length || filterTaken.includes(
      match.team_name === teamName ? 'taken' : 'lost'
    ))
  );

  return (
    <div>
      <div className="filters" style={{marginBottom: '20px', color: 'white', display:'flex' }}>
        <div style={{ padding: '10px' }}>
          <CustomDateInput
            value={startDate?.toISOString().split('T')[0]}
            onChange={handleStartDateChange}
            style={{ marginLeft: '10px' }}
          />
        </div>
        -
        <div style={{ padding: '10px' }}>
          <CustomDateInput
            value={endDate?.toISOString().split('T')[0]}
            onChange={handleEndDateChange}
            style={{ marginLeft: '10px' }}
          />
        </div>
          Objective
          <div className='short-chooser'>
            <CustomSelect options={objectiveOption} selectedOption={selectedObjective} onOptionChange={handleObjectiveChange} />
          </div>
          by {teamName}
          <div className="short-chooser">
            <CustomSelect
              options={takenOptions}
              selectedOption={selectedTaken}
              onOptionChange={handleTakenChange}
            />
          </div>
      </div>
      <div>
        <table style={{backgroundColor: 'white', padding: "5px"}}>
          <thead>
            <tr>
              <th style={{textAlign: "center"}}>
                Time
              </th>
              <th style={{textAlign: "center"}}>
                Draft
              </th>
              <th style={{textAlign: "center"}}>
                Game Number
              </th>
              <th style={{textAlign: "center"}}>
                Team 
              </th>
              <th style={{textAlign: "center"}}>
                Info
              </th>
              <th style={{textAlign: "center"}}>
                Link
              </th>
              <th style={{textAlign: "center"}}>
                ROFL
              </th>
            </tr>
          </thead>

          <tbody>
          {matchData && draftData && filteredMatches.map((event, idx) => {
            let draft = draftData[event.scrimId] || {}; // Ensure draft exists
            console.log(draft)
            return (  Object.keys(draft).length === 0 ? (

              <>
              
              </>
            ) : (

              <tr style={{backgroundColor: idx % 2 == 1 ? "white" : "whitesmoke", marginBottom: "30px"}}>
                <td style={{textAlign: "center"}}>
                  {Math.floor(event.time / 60)}:{String(event.time % 60).padStart(2, '0')}
                </td>
                <td style={{display: 'flex', justifyContent: 'center'}}>
                  {renderChampions(draft, draft.B1R, draft.B2R, draft.B3R, draft.B4R, draft.B5R, true)}
                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                      <img className="small-image" src={TeamIcons[draft.team1]} alt={draft.team1} style={{ width: '25px', marginRight: '5px' }} />
                      {draft.team1} vs {draft.team2}
                      <img className="small-image" src={TeamIcons[draft.team2]} alt={draft.team2} style={{ width: '25px', marginLeft: '5px' }} />
                    </div>
                  {renderChampions(draft, draft.R1R, draft.R2R, draft.R3R, draft.R4R, draft.R5R, false)}
                </td>
                <td style={{textAlign: "center"}}>
                  Game {event.scrimId.slice(-2, -1) === "_" ? event.scrimId.slice(-1): "1" }
                </td>
                <td style={{textAlign: "center"}}>
                    {event.team_name}
                </td>
                <td style={{textAlign: "center"}}>
                  {event.eventLabel}
                </td>
                <td style={{textAlign: "center"}}>
                  {event.Vod === null ? (
                    <>
                      No VOD
                    </>
                  ):(
                  <Link to={event.Vod} target='_blank'>
                    Link
                  </Link>
                  )}
                </td>
                <td>
                  <button className='button-top-page' style={{width: "50%"}} onClick={() => handleROFLClick2(matchData[0].gameId, 1)}>ROFL</button> {/* Open ROFL link */}
                </td>
              </tr>
          )
            )}
          

          )}
          </tbody>
      </table>
      </div>

    </div>
  );
};

export default TeamObjectiveTimersComponent;
