import React from 'react';
import mapPhotsDictionary from '../../data/MapData';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';

const OracleLensComponent = ({ oracleLensData, hoveredOracleLens }) => {
    const mapWidth = 500;
    const mapHeight = 500;
    const minX = -120;
    const minY = -120;
    const maxX = 14970;
    const maxY = 14980;

    const captureScreenshot = () => {
        const element = document.getElementById("heatmap-container-3");

        html2canvas(element)
            .then((canvas) => {
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = 'screenshot.png';
                link.href = image;
                link.click();
            })
            .catch(err => {
                console.error('Error capturing screenshot', err);
            });
    };

    const translateCoordinates = (x, y) => {
        const newX = ((x - minX) / (maxX - minX)) * mapWidth;
        const newY = ((maxY - y) / (maxY - minY)) * mapHeight;
        return { newX, newY };
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div id="heatmap-container-3" style={{ position: 'relative' }}>
                <img style={{ position: 'relative', width: `${mapWidth}px`, height: `${mapHeight}px` }}
                     src={mapPhotsDictionary["SummonersRift"]}
                     alt="Summoner's Rift Map" />

                <svg
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    width={mapWidth} height={mapHeight}
                >
                    <defs>
                        {/* Define Blue Gradient */}
                        <linearGradient id="blueGradient" x1="0" y1="0" x2="1" y2="1">
                            <stop offset="0%" stopColor="white" />
                            <stop offset="100%" stopColor="blue" />
                        </linearGradient>

                        {/* Define Red Gradient */}
                        <linearGradient id="redGradient" x1="0" y1="0" x2="1" y2="1">
                            <stop offset="0%" stopColor="white" />
                            <stop offset="100%" stopColor="red" />
                        </linearGradient>

                        <linearGradient id="yellowGradient" x1="0" y1="0" x2="1" y2="1">
                            <stop offset="0%" stopColor="white" />
                            <stop offset="100%" stopColor="yellow" />
                        </linearGradient>
                    </defs>

                    {oracleLensData.map((movement, index) => {
                        if (movement.positions.length < 2) return null;
                        const isHovered = hoveredOracleLens && hoveredOracleLens === movement; // Check if the current ward is the hovered one

                        const pathPoints = movement.positions
                            .map(pos => {
                                const { newX, newY } = translateCoordinates(pos.x, pos.y);
                                return `${newX},${newY}`;
                            })
                            .join(" ");

                        return (
                            <g key={index}>
                                {/* Main Path with Gradient */}
                                <polyline
                                    points={pathPoints}
                                    stroke={isHovered ? "url(#yellowGradient)" : movement.positions[0]?.side === 'blue' ? "url(#blueGradient)" : "url(#redGradient)"}
                                    strokeWidth="3"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                        );
                    })}
                </svg>
            </div>
            <button style={{ width: "100%", marginTop: "10px" }}
                    className='button-top-page'
                    onClick={() => captureScreenshot()}>
                Screenshot
            </button>
        </div>
    );
};

export default OracleLensComponent;
