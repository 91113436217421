

export const handleROFLClick2 = async (gameId, official) => {
    try {
        window.open(`https://sanchidataportal.com/api/rofl_download/?game_id=${gameId}&official=${official}&apikey=${localStorage.getItem('apikey')}`, "_blank");
    } catch (error) {
        console.error("Error:", error);
        alert("Error downloading ROFL file. Please try again.");
    }
};

export const handleVODClick = (vodLink) => {
    if (vodLink) {
      window.open(vodLink, "_blank"); // Opens the VOD link in a new tab
    } else {
      alert("No VOD link available.");
    }
}

export const handleVODClick2 = (vodLink, time) => {
    if (vodLink.includes('t=')) {
      const fixedVod = vodLink.replace(/&amp;/g, '&');
      const [baseUrl, queryString] = fixedVod.split('?');
      const params = new URLSearchParams(queryString);
      
      let timestamp = params.get('t'); // Extract timestamp
      let vodLink2 = baseUrl
      if (timestamp && !isNaN(timestamp) && typeof time === 'number') {
        vodLink2 = `${baseUrl}?t=${Number(timestamp) + time - 30}`;
      }
      window.open(vodLink2, "_blank"); // Opens the VOD link in a new tab
  } else {
    alert("No VOD link available.");
  }
}