import React, { useEffect, useState } from 'react';
import { useSeason } from '../SeasonContext';
import axiosInstance from '../backend/axiosInstance';
import roleIcon from '../data/Role';
import Select from 'react-select';

const ExternalFactorsComponent = () => {
  const { currentSeasonTime } = useSeason();

  document.title = "External Factors";
  const [teamMatesData, setTeamMatesData] = useState(null);
  const [formData, setFormData] = useState({}); // Store user input


  useEffect(() => {
    const teamName = localStorage.getItem("team");
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    const today = new Date().toISOString().split('T')[0];

    axiosInstance.get(`teammates/?name=${encodeURIComponent(teamName)}`+ '&start=' + start + "&end=" + end)
      .then(response => {
        const sortedData = response.data.sort((a, b) => a.Role - b.Role);
        
        axiosInstance.get(`/externalfactors/?date=${today}`)
        .then((existingData) => {
            const existingRecords = existingData.data.reduce((acc, record) => {
                acc[record.summonerName] = record;
                return acc;
            }, {});
            console.log(existingRecords)
            const initialFormData = {};
            sortedData.forEach(teammate => {
                initialFormData[teammate.summonerName] = existingRecords[teammate.summonerName] || {
                    sleepTime: 0,
                    sleepQuality: 1,
                    activity: "average",
                    stressLevel: 1,
                    generalMood: 5,
                };
            });
            console.log("Initial Form Data:", initialFormData);
            setFormData(initialFormData);
            setTeamMatesData(sortedData);
        })
        .catch(error => console.error("Error fetching external factors:", error));
      })
      .catch(error => {
        console.error(error);
      });

 },  [ currentSeasonTime]);

    const handleInputChange = (summonerName, field, value) => {
        setFormData(prevState => ({
        ...prevState,
        [summonerName]: {
            ...prevState[summonerName],
            [field]: value
        }
        }));

        axiosInstance.post("/externalfactors/", {
        player_name: summonerName,
        date: new Date().toISOString().split('T')[0], // Current date
        ...formData[summonerName],
        [field]: value, // Update only the changed field
        })
        .then(response => console.log(response.data))
        .catch(error => console.error("Failed to update:", error));
    };


  return (
    <div>
      <div>
        <table style={{color: 'white'}}>
            <thead>
                <tr>
                    <th>
                        Player Name
                    </th>
                    <th>
                        Sleep Time (h)
                    </th>
                    <th>
                        Sleep quality
                    </th>
                    <th>
                        Activity level per day
                    </th>
                    <th>
                        Stress level
                    </th>
                    <th>
                        General mood 
                    </th>
                </tr>
            </thead>
            <tbody>

            {teamMatesData && teamMatesData.map((teammate)=> (
                <tr>
                    <td>
                        <div>
                            <img src={roleIcon[teammate.Role]} alt={`Role ${teammate.Role} Icon`} className="role-icon"/>
                            <span className="teammate-name-2" style={{color: 'white'}}>{teammate.summonerName}</span>
                        </div>
                    </td>
                    <td style={{textAlign: "center", color: 'black'}}>
                        <Select
                          options={Array.from({ length: 17 }, (_, i) => ({ value: i, label: i.toString() }))}
                          value={{ value: formData[teammate.summonerName]?.sleepTime, label: formData[teammate.summonerName]?.sleepTime }}
                          onChange={option => handleInputChange(teammate.summonerName, "sleepTime", option.value)}
                        />
                    </td>
                    <td>
                        <input
                            style={{ width: "100%" }}
                            type="range"
                            min="1"
                            max="10"
                            step="1"
                            value={formData[teammate.summonerName]?.sleepQuality}
                            onChange={(e) => handleInputChange(teammate.summonerName, "sleepQuality", Number(e.target.value))}
                            list={`tickmarks-${teammate.summonerName}-sleep`} // Unique ID for each slider
                        />
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            {[...Array(10).keys()].map((num) => (
                                <span key={num+1} style={{ fontSize: "12px", fontFamily: "monospace", minWidth: "15px", textAlign: "center" }}>
                                    {num+1}
                                </span>
                            ))}
                        </div>

                        {/* Mood Indicators */}
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                            <span style={{ color: "blue" }}>💤</span>
                            <span>😐</span>
                            <span>💪</span>
                        </div>
                    </td>
                    <td style={{ textAlign: "center" , color: "black"}}>
                        <Select
                            options={[
                                { value: "small", label: "Small" },
                                { value: "average", label: "Average" },
                                { value: "big", label: "Big" }
                            ]}
                            value={{ value: formData[teammate.summonerName]?.activity, label: formData[teammate.summonerName]?.activity }}
                            onChange={option => handleInputChange(teammate.summonerName, "activity", option.value)}
                        />
                    </td>
                    <td>
                        <input
                            style={{ width: "100%" }}
                            type="range"
                            min="1"
                            max="10"
                            step="1"
                            list={`tickmarks-${teammate.summonerName}-sleep`} // Unique ID for each slider
                            value={formData[teammate.summonerName]?.stressLevel}
                            onChange={(e) => handleInputChange(teammate.summonerName, "stressLevel", Number(e.target.value))}
                        />
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            {[...Array(10).keys()].map((num) => (
                                <span key={num+1} style={{ fontSize: "12px", fontFamily: "monospace", minWidth: "15px", textAlign: "center" }}>
                                    {num+1}
                                </span>
                            ))}
                        </div>
                    </td>
                    <td style={{ textAlign: "center", position: "relative" }}>
                        <input
                            type="range"
                            min="0"
                            max="10"
                            step="1"
                            value={formData[teammate.summonerName]?.generalMood}
                            onChange={(e) => handleInputChange(teammate.summonerName, "generalMood", Number(e.target.value))}
                            style={{
                                width: "100%",
                                background: "linear-gradient(to right, red, orange, purple, blue)",
                                appearance: "none",
                                height: "8px",
                                borderRadius: "5px",
                                outline: "none"
                            }}
                        />
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                            {[...Array(10).keys()].map((num) => (
                                <span key={num+1} style={{ fontSize: "12px", fontFamily: "monospace", minWidth: "15px", textAlign: "center" }}>
                                    {num+1}
                                </span>
                            ))}
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "5px" }}>
                            <span>😞</span> <span>😐</span> <span>😊</span> <span>😁</span>
                        </div>
                    </td>

                </tr >
            ))}

            </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExternalFactorsComponent;
