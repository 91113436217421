import SummonerFlash from '../../summonerspells_photos/SummonerFlash.png';
import SummonerHaste from '../../summonerspells_photos/SummonerHaste.png';
import SummonerSmite from '../../summonerspells_photos/SummonerSmite.png';
import SummonerHeal from '../../summonerspells_photos/SummonerHeal.png';
import SummonerTeleport from '../../summonerspells_photos/SummonerTeleport.png';
import SummonerSmiteAvatarUtility from '../../summonerspells_photos/1102_smite.png';
import SummonerSmiteAvatarOffensive from '../../summonerspells_photos/1101_smite.png';
import SummonerSmiteAvatarDefensive from '../../summonerspells_photos/1103_smite.png';
import SummonerDot from '../../summonerspells_photos/SummonerDot.png';
import Unleashed_Smite from '../../summonerspells_photos/Unleashed_Smite.webp';
import SummonerFlashPerksHextechFlashtraptionV2 from '../../summonerspells_photos/Hexflash.webp';
import SummonerExhaust from '../../summonerspells_photos/SummonerExhaust.png';
import SummonerBarrier from '../../summonerspells_photos/summonerbarrier.png';
import SummonerBoost from  '../../summonerspells_photos/SummonerBoost.png';

const summonerSpellDictionary = {
    SummonerFlash,
    SummonerHaste,
    SummonerSmite,
    SummonerHeal,
    SummonerExhaust,
    SummonerTeleport,
    S5_SummonerSmitePlayerGanker: Unleashed_Smite,
    SummonerSmiteAvatarUtility,
    S12_SummonerTeleportUpgrade: SummonerTeleport,
    SummonerBarrier,
    SummonerDot,
    SummonerSmiteAvatarOffensive,
    SummonerFlashPerksHextechFlashtraptionV2,
    SummonerSmiteAvatarDefensive,
    SummonerBoost,

    1: SummonerBoost,
    3: SummonerExhaust,
    4: SummonerFlash,
    6: SummonerHaste,
    7: SummonerHeal,
    12: SummonerTeleport,
    14: SummonerDot,
    11: Unleashed_Smite,
    21: SummonerBarrier,
}

export default summonerSpellDictionary;