import { championData, championPhotos } from './data/ChampionsIcon';
import React, { useEffect, useState } from 'react';
import CustomSelect from './base/CustomSelect';
import axiosInstance from './backend/axiosInstance';
import { getGreenCellColor, getRedGreenCellno0 } from './base/colorUtils';
import { useSeason } from './SeasonContext';

const ChampionsComponent = () => {
  const { currentSeasonTime } = useSeason();

  document.title = "Champions";
  const [selectedLeague, setSelectedLeague] = useState([]);
  const [filterLeague, setFilterLeague] = useState([]);
  const [leagueOption, setLeagueOption] = useState([]);

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [championOccurrences, setChampionOccurrences] = useState({});
  const [matchData, setMatchData] = useState([]);
  const [championStatsData, setChampionStatsData] = useState([])

  const [sortColumn, setSortColumn] = useState('championName'); // Default sort column
  const [sortDirection, setSortDirection] = useState('desc');

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());

    axiosInstance.get('parsed_official_games/?start=' + start + "&end=" + end)
    .then((response) => {
      setMatchData(response.data);
      const uniqueLeagues = [...new Set(response.data.map((match) => match.leagueName))];
      const uniquePatches = [...new Set(response.data.map((match) => match.patch))];
      const filteredLeagues = uniqueLeagues.filter((league) => league !== null);
      const formattedLeagueOptions = filteredLeagues.sort().map((league) => ({
        value: league,
        label: league,
      }));

      const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
        value: patch,
        label: patch,
      }));
      setLeagueOption(formattedLeagueOptions);
      setPatchOption(formattedPatchOptions);
    })

}, []);

useEffect(() => {
  if (matchData)
  {
      const filteredMatchData = matchData.filter((match) => {
        const leagueId = match.leagueName; // Assuming you have a property in your match data for the league
        const patchId = match.patch; // Assuming you have a property in your match data for the patch

        const isLeagueSelected = filterLeague.length === 0 || filterLeague.includes(leagueId);
        const isPatchSelected = filterPatch.length === 0 || filterPatch.includes(patchId);

        return isLeagueSelected && isPatchSelected;
      });

    const occurrences = countChampionOccurrences(filteredMatchData);
    const calculatedChampionStatsData = {};

    Object.keys(championData).forEach(championId => {
      calculatedChampionStatsData[championData[championId]] = {
        ...occurrences[championId], // Merge occurrence data
      };
    });

    setChampionStatsData(calculatedChampionStatsData);
    setChampionOccurrences(occurrences)
  }
}, [matchData, filterLeague, filterPatch]);

function countChampionOccurrences(matchData) {
  const championOccurrences = {};
  const wins = {}; // To store the wins for each champion
  Object.keys(championData).forEach(championId => {
    championOccurrences[championId] = { bans: 0, picks: 0 };
    wins[championId] = 0;
  });

  matchData.forEach(match => {
    for (let i = 1; i <= 5; i++) {
      const blueBanKey = `BB${i}`;
      const redBanKey = `RB${i}`;
      const bluePickKey = `BP${i}`;
      const redPickKey = `RP${i}`;

      const blueBanChampionId = match[blueBanKey];
      const redBanChampionId = match[redBanKey];
      const bluePickChampionId = match[bluePickKey];
      const redPickChampionId = match[redPickKey];

      // Process bans
      if (blueBanChampionId) {
        if (championOccurrences[blueBanChampionId]) {
          championOccurrences[blueBanChampionId].bans++;
        } else {
          championOccurrences[blueBanChampionId] = { bans: 1, picks: 0 };
        }
      }

      if (redBanChampionId) {
        if (championOccurrences[redBanChampionId]) {
          championOccurrences[redBanChampionId].bans++;
        } else {
          championOccurrences[redBanChampionId] = { bans: 1, picks: 0 };
        }
      }

      // Process picks
      if (bluePickChampionId) {
        if (championOccurrences[bluePickChampionId]) {
          championOccurrences[bluePickChampionId].picks++;
          if (match.winner === 100) {
            wins[bluePickChampionId]++;
          }
        } else {
          championOccurrences[bluePickChampionId] = { bans: 0, picks: 1 };
        }
      }

      if (redPickChampionId) {
        if (championOccurrences[redPickChampionId]) {
          championOccurrences[redPickChampionId].picks++;
          if (match.winner === 200) {
            wins[redPickChampionId]++;
          }
        } else {
          championOccurrences[redPickChampionId] = { bans: 0, picks: 1 };
        }
      }
    }
  });

  Object.keys(championOccurrences).forEach(championId => {
    const picks = championOccurrences[championId]?.picks || 0;
    const bans = championOccurrences[championId]?.bans || 0;
    const totalGames = picks + bans;
    let winRate;
    if (picks === 0) {
      winRate = 0;
    } else {
      winRate = (wins[championId] / picks) * 100;
    }
    const presence = (totalGames / matchData.length) * 100;
    championOccurrences[championId].winRate = winRate;
    championOccurrences[championId].presence = presence;
  });

  return championOccurrences;
}

const handleSort = (column) => {
  if (column === sortColumn) {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  } else {
    setSortColumn(column);
    setSortDirection('asc');
  }
};

const handleRowClick = (championName) => {
  const searchQuery = encodeURIComponent(championName); // Encode the value for URL
  window.location.href = `/champions/${searchQuery}`;
};

const handlePatchChange = selectedOptions => {
  const leagueValues = selectedOptions.map(option => option.value);
  setFilterPatch(leagueValues);
  setSelectedPatch(selectedOptions);
};

const handleLeagueChange = (selectedOptions) => {
  const leagueValues = selectedOptions.map((option) => option.value);
  setFilterLeague(leagueValues);
  setSelectedLeague(selectedOptions);
};

return (

  <div className='main'>
    <div className='filters'>
      Patch
      <div className='short-chooser'>
        <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
      </div>
      League
      <div className='league-chooser'>
        <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
      </div>
    </div>
    <div style={{ color: 'white', backgroundColor: 'black', padding: "10px", width: "100%" }}>

      {championOccurrences && Object.keys(championStatsData).length > 0 && (
      <>
      <h2>Champion Occurrences:</h2>
      <table style={{color: 'black', backgroundColor: 'white', width: "30%"}}>
        <thead>
          <tr>
            <th onClick={() => handleSort('championName')}>Champion</th>
            <th onClick={() => handleSort('picks')}>Picks</th>
            <th onClick={() => handleSort('bans')}>Bans</th>
            <th onClick={() => handleSort('winRate')}>Win Rate</th>
            <th onClick={() => handleSort('presence')}>Presence</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(championOccurrences)
            .filter(championId => championId !== '0')
            .sort((a, b) => {
              const aValue = championOccurrences[a][sortColumn];
              const bValue = championOccurrences[b][sortColumn];
              return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
            })
            .map(championId => {
              const { picks, bans, winRate, presence } = championOccurrences[championId];
              return (
                <tr key={championId}>
                  <td onClick={() => handleRowClick(championData[championId])} style={{ cursor: 'pointer' }}>
                    <img
                      src={championPhotos[championData[championId]]}
                      alt=''
                      className='small-image'
                    />
                    {championData[championId]}
                  </td>
                  <td>{picks}</td>
                  <td>{bans}</td>
                  <td style={{backgroundColor: getRedGreenCellno0(winRate.toFixed(2), 0, 100) }}>{winRate.toFixed(2)}%</td>
                  <td style={{backgroundColor: getGreenCellColor(presence.toFixed(2), 100) }}>{presence.toFixed(2)}%</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  )}

    </div>
  </div>
);};

export default ChampionsComponent;
