import React, { useEffect, useState } from 'react';
import CustomSelect from './base/CustomSelect';
import { championPhotos, championData } from "./data/ChampionsIcon";
import "./TeamDraft.css";
import { useSeason } from './SeasonContext';
import ChampionRoleStats from './ChampionRoleStats';
import axiosInstance from './backend/axiosInstance';
import Select from 'react-select';


const SynergiesComponent = ({ initialTeamName, initialLeagueName }) => {
    const {currentSeasonTime } = useSeason();

    document.title = "Synergies";

    const [championOccurrences, setChampionOccurrences] = useState({});

    const [selectedLeague, setSelectedLeague] = useState([]);
    const [filterLeague, setFilterLeague] = useState([]);
    const [leagueOption, setLeagueOption] = useState([]);

    const [selectedPatch, setSelectedPatch] = useState([]);
    const [filterPatch, setFilterPatch] = useState([]);
    const [patchOption, setPatchOption] = useState([]);

    const [selectedTeam, setSelectedTeam] = useState([]);
    const [filterTeam, setFilterTeam] = useState([]);
    const [teamOption, setTeamOption] = useState([]);


    const [selectedChampion, setSelectedChampion] = useState([]);
    const [filterChampion, setFilterChampion] = useState([]);
    const [championOption, setChampionOption] = useState([]);

    const [selectedTeamSide, setSelectedTeamSide] = useState({ value: "any", label: 'Any' });
    const [filterTeamSide, setFilterTeamSide] = useState(["any"]);

    const [matchData, setMatchData] = useState([]);
    const [filteredMatchData, setFilteredMatchData] = useState([]);

    const teamSideOptions = [
        { value: "any", label: 'Any' }, // Change the value to "any"
        { value: "team1", label: 'Blue' },
        { value: "team2", label: 'Red' },
      ];

      const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
      const [selectedFilterChampion, setSelectedFilterChampion] = useState(Array(4).fill());
      const [selectedFilterRole, setSelectedFilterRole] = useState(Array(4).fill({ value: 'any', label: 'Any' }));
      const [selectedFilterType, setSelectedFilterType] = useState(Array(4).fill('any'));
      const [selectedFilterRotation, setSelectedFilterRotation] = useState(Array(4).fill('any'));

      useEffect(() => {
        const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
        const fetchData = async () => {
            try {
                const [officialsResponse, scrimsResponse] = await Promise.all([
                  axiosInstance.get(`parsed_official_games/?start=${start}&end=${end}`),
                  axiosInstance.get(`parsedscrims/?team=${localStorage.getItem("team")}`)
                ]);

                const officials = officialsResponse.data.map(match => ({ ...match, type: "official" }));
                const scrims = scrimsResponse.data.map(match => ({ ...match, leagueName: "SCRIMS", type: "scrim" }));

                const combinedData = [...officials, ...scrims].sort((a, b) => new Date(b.date) - new Date(a.date));
                setMatchData(combinedData);

                const uniqueLeagues = [...new Set(combinedData.map((match) => match.leagueName))];
                const uniquePatches = [...new Set(combinedData.map((match) => match.patch))]
                .filter(patch => patch !== null)
                .sort((a, b) => {
                  const partsA = a.split('.').map(Number);
                  const partsB = b.split('.').map(Number);

                  if (partsA[0] !== partsB[0]) {
                    return partsA[0] - partsB[0];
                  }
                  return partsA[1] - partsB[1];
                });

                const uniqueTeams = getUniqueTeams(combinedData);
                const filteredLeagues = uniqueLeagues.filter((league) => league !== null);

                const formattedLeagueOptions = filteredLeagues.sort().map((league) => ({
                    value: league,
                    label: league,
                }));

                const formattedPatchOptions = uniquePatches.map((patch) => ({
                    value: patch,
                    label: patch,
                }));


                const formattedTeamOptions = uniqueTeams.map((team) => ({
                    value: team,
                    label: team,
                }));
                const formattedChampionOptions = Object.entries(championData).map(([id, champion]) => ({
                    value: id,
                    label: champion,
                })).sort((a, b) => a.label.localeCompare(b.label));

                setLeagueOption(formattedLeagueOptions);
                setPatchOption(formattedPatchOptions);
                setTeamOption(formattedTeamOptions);
                setChampionOption(formattedChampionOptions);

                if (initialTeamName) {
                  const matchedTeam = formattedTeamOptions.find(option => option.value === initialTeamName);
                  if (matchedTeam) {
                    handleTeamChange([matchedTeam]);
                  }
                }
                if (initialLeagueName) {
                  const matchedLeague = formattedLeagueOptions.find(option => option.value === initialLeagueName);
                  if (matchedLeague) {
                    handleLeagueChange([matchedLeague]);
                  }
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentSeasonTime]);

    const getUniqueTeams = (matchData) => {
      const teamsSet = new Set();
      matchData.forEach((match) => {
        teamsSet.add(match.team1);
        teamsSet.add(match.team2);
      });
      return [...teamsSet];
    };

    useEffect(() => {

      const filteredMatches = matchData.filter((match) => {
        const isLeagueMatch = filterLeague.length === 0 || filterLeague.includes(match.leagueName);
        const isPatchMatch = filterPatch.length === 0 || filterPatch.includes(match.patch);
        const isTeamMatch = filterTeam.length === 0 || (filterTeam.includes(match.team1) && filterTeamSide[0] !== "team2") || (filterTeam.includes(match.team2) && filterTeamSide[0] !== "team1");
        const isGood = match.RP1 !== null;

        const matchChampions = [
            match.BP1, match.BP2, match.BP3, match.BP4, match.BP5, match.RP1, match.RP2, match.RP3, match.RP4, match.RP5
        ].filter(Boolean);

        const isChampionMatch =
            filterChampion.length === 0 ||
            filterChampion.includes('any') ||
            filterChampion.every(championId =>
                matchChampions.includes(Number(championId)) // Check if all selected champions are present in the match
            );

        const isAdditionalFilterMatch = selectedFilterChampion.every((champion, index) => {
            if (!champion || champion.value === 'any') return true;
            const role = selectedFilterRole[index];
            const type = selectedFilterType[index];
            const rotation = selectedFilterRotation[index];

            const championId = Number(champion.value);
            const championIndex = matchChampions.indexOf(championId);

            const side = championIndex < 5 ? 'B' : 'R';
            const position = championIndex < 5 ? championIndex + 1 : championIndex - 4;

            const roleKey = `${side}${position}R`;
            const typeKey = `${side}${position}BC`;

            const isRoleMatch = role.value === 'any' || String(match[roleKey]) === String(role.value);
            const isTypeMatch = type === 'any' || match[typeKey] === type[0].toUpperCase();
            let isRotationMatch = false;
            if (rotation === 'any') {
                isRotationMatch = true;
                if (championIndex === -1) return false;
            } else if (rotation === 'R1-R2') {
                isRotationMatch = (side === 'R' && position <= 2);
            } else if (rotation === 'R3') {
                isRotationMatch = (side === 'R' && position === 3);
            } else if (rotation === 'R4') {
                isRotationMatch = (side === 'R' && position === 4);
            } else if (rotation === 'R5') {
                isRotationMatch = (side === 'R' && position === 5);
            } else if (rotation === 'B1') {
                isRotationMatch = (side === 'B' && position === 1);
            } else if (rotation === 'B2-B3') {
                isRotationMatch = (side === 'B' && (position === 2 || position === 3));
            } else if (rotation === 'B4-B5') {
                isRotationMatch = (side === 'B' && (position === 4 || position === 5));
            } else if (rotation === 'BB1-BB3') {
              isRotationMatch = (Number(champion.value) === match[`BB1`] || Number(champion.value) === match[`BB2`] || Number(champion.value) === match[`BB3`]);
            } else if (rotation === 'BB4-BB5') {
              isRotationMatch = (Number(champion.value) === match[`BB4`] || Number(champion.value) === match[`BB5`]);
            } else if (rotation === 'RB4-RB5') {
              isRotationMatch = (Number(champion.value) === match[`RB4`] || Number(champion.value) === match[`RB5`]);
            } else if (rotation === 'RB1-RB3') {
              isRotationMatch = (Number(champion.value) === match[`RB1`] || Number(champion.value) === match[`RB2`] || Number(champion.value) === match[`RB3`]);
            }
            return isRoleMatch && isTypeMatch && isRotationMatch;
        });

        return isPatchMatch && isTeamMatch && isChampionMatch && isGood && isLeagueMatch && isAdditionalFilterMatch;
    });

    setFilteredMatchData(filteredMatches)
    setChampionOccurrences(countChampionOccurrences(filteredMatches));

    const uniquePatchesForLeague = [...new Set(filteredMatches.map((match) => match.patch))]
    .filter(patch => patch !== null)
    .sort((a, b) => {
      const partsA = a.split('.').map(Number);
      const partsB = b.split('.').map(Number);

      if (partsA[0] !== partsB[0]) {
        return partsA[0] - partsB[0];
      }
      return partsA[1] - partsB[1];
    });
    const uniqueTeamsForLeague = getUniqueTeams(filteredMatches);

    const formattedPatchOptions = uniquePatchesForLeague.map((patch) => ({
      value: patch,
      label: patch,
    }));

    const formattedTeamOptions = uniqueTeamsForLeague
    .sort((a, b) => a.localeCompare(b)) // Sort the team names alphabetically
    .map((team) => ({
      value: team,
      label: team,
    }));

    setTeamOption(formattedTeamOptions);

}, [matchData, filterPatch, filterTeam, filterChampion, filterLeague, filterTeamSide, selectedFilterChampion, selectedFilterRole, selectedFilterType, selectedFilterRotation]);

    function countChampionOccurrences(matchData) {
      const championOccurrences = {};
      const wins = {};
      Object.keys(championData).forEach(championId => {
        championOccurrences[championId] = { bans: 0, picks: 0 };
        wins[championId] = 0;
      });

      matchData.forEach(match => {
        for (let i = 1; i <= 5; i++) {
          const blueBanKey = `BB${i}`;
          const redBanKey = `RB${i}`;
          const bluePickKey = `BP${i}`;
          const redPickKey = `RP${i}`;

          const blueBanChampionId = match[blueBanKey];
          const redBanChampionId = match[redBanKey];
          const bluePickChampionId = match[bluePickKey];
          const redPickChampionId = match[redPickKey];
          if (blueBanChampionId) {
            if (championOccurrences[blueBanChampionId]) {
              championOccurrences[blueBanChampionId].bans++;
            } else {
              championOccurrences[blueBanChampionId] = { bans: 1, picks: 0 };
            }
          }

          if (redBanChampionId) {
            if (championOccurrences[redBanChampionId]) {
              championOccurrences[redBanChampionId].bans++;
            } else {
              championOccurrences[redBanChampionId] = { bans: 1, picks: 0 };
            }
          }

          if (bluePickChampionId) {
            if (championOccurrences[bluePickChampionId]) {
              championOccurrences[bluePickChampionId].picks++;
              if (match.winner === 100) {
                wins[bluePickChampionId]++;
              }
            } else {
              championOccurrences[bluePickChampionId] = { bans: 0, picks: 1 };
            }
          }

          if (redPickChampionId) {
            if (championOccurrences[redPickChampionId]) {
              championOccurrences[redPickChampionId].picks++;
              if (match.winner === 200) {
                wins[redPickChampionId]++;
              }
            } else {
              championOccurrences[redPickChampionId] = { bans: 0, picks: 1 };
            }
          }
        }
      });

      Object.keys(championOccurrences).forEach(championId => {
        const picks = championOccurrences[championId]?.picks || 0;
        const bans = championOccurrences[championId]?.bans || 0;
        const totalGames = picks + bans;
        let winRate;
        if (picks === 0) {
          winRate = 0;
        } else {
          winRate = (wins[championId] / picks) * 100;
        }
        const presence = (totalGames / matchData.length) * 100;
        championOccurrences[championId].winRate = winRate;
        championOccurrences[championId].presence = presence;
      });

      return championOccurrences;
    }

    const handleLeagueChange = (selectedOptions) => {
        const leagueValues = selectedOptions.map((option) => option.value);
        setFilterLeague(leagueValues);
        setSelectedLeague(selectedOptions);
      };

      const handleTeamChange = (selectedOptions) => {
        const leagueValues = selectedOptions.map((option) => option.value);
        setFilterTeam(leagueValues);
        setSelectedTeam(selectedOptions);
      };

      const handleTeamSideChange = (selectedOption) => {
        setFilterTeamSide([selectedOption.value]); // Ensure that the value is wrapped in an array
        setSelectedTeamSide(selectedOption);
      };

      const handlePatchChange = (selectedOptions) => {
        const leagueValues = selectedOptions.map((option) => option.value);
        setFilterPatch(leagueValues);
        setSelectedPatch(selectedOptions);
      };

      const handleChampionChange = (selectedOptions) => {
        const championValues = selectedOptions.map((option) => option.value);
        const championIds = championValues.map(Number);
        setFilterChampion(championIds);
        setSelectedChampion(selectedOptions);
      };




    return (
      <div className='wrapper'>

        <div className='filters'>
          League
          <div className='short-chooser'>
            <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
          </div>
          Patch
          <div className='short-chooser'>
            <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
          </div>
          Team
          <div className='short-chooser'>
            <CustomSelect options={teamOption} selectedOption={selectedTeam} onOptionChange={handleTeamChange} />
          </div>
          Side
          <div style={{padding: "10px", color: "black"}}>
            <Select
              value={selectedTeamSide}
              onChange={handleTeamSideChange}
              options={teamSideOptions}
              style={{color: "black"}}
            />
          </div>
          Champion
          <div className='short-chooser'>
            <CustomSelect options={[{ value: 'any', label: 'Any' }, ...championOption]} selectedOption={selectedChampion} onOptionChange={handleChampionChange} />
          </div>
          <div className='filter-toggle'>
            <button onClick={() => setIsFilterMenuVisible(!isFilterMenuVisible)}>
              {"Filters"}
            </button>
          </div>
        </div>
        {isFilterMenuVisible && (
          <div className='new-filter' style={{ display: "flex", flexWrap: 'wrap', justifyContent: 'center', marginBottom: "20px",  backgroundColor: "white", borderRadius: "10px" }}>
            {[...Array(4).keys()].map((_, i) => (
              <div key={i} className='filter-row' style={{ display: "flex", width: "50%", justifyContent: "center" }}>
                <div>
                  <label>Champion:</label>
                  <select
                    value={selectedFilterChampion[i] ? selectedFilterChampion[i].value : 'any'}
                    onChange={(e) => {
                      const selected = championOption.find(option => option.value === e.target.value) || { value: 'any', label: 'Any' };
                      const newFilterChampion = [...selectedFilterChampion];
                      newFilterChampion[i] = selected;
                      setSelectedFilterChampion(newFilterChampion);
                    }}
                  >
                    <option value="any">Any</option>
                    {championOption.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Role:</label>
                  <select
                    value={selectedFilterRole[i] ? selectedFilterRole[i].value : 'any'}
                    onChange={(e) => {
                      const selected = { value: e.target.value, label: e.target.value };
                      const newFilterRole = [...selectedFilterRole];
                      newFilterRole[i] = selected;
                      setSelectedFilterRole(newFilterRole);
                    }}
                  >
                    <option value="any">Any</option>
                    <option value="0">TOP</option>
                    <option value="1">JGL</option>
                    <option value="2">MID</option>
                    <option value="3">ADC</option>
                    <option value="4">SUP</option>
                  </select>
                </div>
                <div>
                  <label>Type:</label>
                  <select
                    value={selectedFilterType[i]}
                    onChange={(e) => {
                      const newFilterType = [...selectedFilterType];
                      newFilterType[i] = e.target.value;
                      setSelectedFilterType(newFilterType);
                    }}
                  >
                    <option value="any">Any</option>
                    <option value="blind">Blind</option>
                    <option value="counter">Counter</option>
                  </select>
                </div>
                <div>
                  <label>Rotation:</label>
                  <select
                    value={selectedFilterRotation[i]}
                    onChange={(e) => {
                      const newFilterRotation = [...selectedFilterRotation];
                      newFilterRotation[i] = e.target.value;
                      setSelectedFilterRotation(newFilterRotation);
                    }}
                  >
                    <option value="any">Any</option>
                    <option value="R1-R2">R1-R2</option>
                    <option value="R3">R3</option>
                    <option value="R4">R4</option>
                    <option value="R5">R5</option>
                    <option value="B1">B1</option>
                    <option value="B2-B3">B2-B3</option>
                    <option value="B4-B5">B4-B5</option>
                    <option value="BB1-BB3">BB1-BB3</option>
                    <option value="BB4-BB5">BB4-BB5</option>
                    <option value="RB1-RB3">RB1-RB3</option>
                    <option value="RB4-RB5">RB4-RB5</option>
                  </select>
                </div>
              </div>
            ))}
          </div>
        )}
          <div style={{ width: "100%"}}>
              <ChampionRoleStats filteredMatches={filteredMatchData} filteredTeam={filterTeam}/>
          </div>
      </div>
    );
};

export default SynergiesComponent;
