import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { championData, championPhotos } from '../data/ChampionsIcon';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import roleIcon from '../data/Role';
import { classesPhotos } from '../data/ClassesIcon';
import axiosInstance from '../backend/axiosInstance';

const initialFrames = {
  row1: ['Frame 1', 'Frame 2', 'Frame 3'],
  row2: ['Frame 4', 'Frame 5', 'Frame 6', 'Frame 7', 'Frame 8']
};

const rolesPublic = ['top', 'jgl', 'mid', 'bot', 'sup'];

const sortChampionIds = (championIds) => {
  return championIds.sort((a, b) => {
    const nameA = championData[a]?.toLowerCase();
    const nameB = championData[b]?.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

const initialChampions = Object.keys(championData);

const TierlistCustomFrames = () => {
  const [frames, setFrames] = useState(initialFrames);
  const [champions, setChampions] = useState({
    top:{
      pool: initialChampions,
      ...Object.fromEntries([...frames.row1, ...frames.row2].map(name => [name, []]))
    },
    jgl:{
      pool: initialChampions,
      ...Object.fromEntries([...frames.row1, ...frames.row2].map(name => [name, []]))
    },
    mid:{
      pool: initialChampions,
      ...Object.fromEntries([...frames.row1, ...frames.row2].map(name => [name, []]))
    },
    bot:{
      pool: initialChampions,
      ...Object.fromEntries([...frames.row1, ...frames.row2].map(name => [name, []]))
    },
    sup:{
      pool: initialChampions,
      ...Object.fromEntries([...frames.row1, ...frames.row2].map(name => [name, []]))
    },
  }
);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentRole, setCurrentRole] = useState('top');
  const [championTags, setChampionTags] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [classes, setClasses] = useState([]);
  const [showCombined, setShowCombined] = useState(false);
  
  const toggleCombinedView = () => {
    setShowCombined(!showCombined);
  };
  
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
  
    setChampions(prevChampions => {
      const updatedChampions = { ...prevChampions };
      const roleChampions = updatedChampions[currentRole];
  
      // Remove the dragged champion from its original location
      roleChampions[source.droppableId] = roleChampions[source.droppableId].filter(id => id !== draggableId);
  
      // Check if the destination frame already has a champion
      const replacedChampion = roleChampions[destination.droppableId]?.[0];
  
      // Add replaced champion back to the pool if there was one
      if (replacedChampion) {
        roleChampions.pool = [...roleChampions.pool, replacedChampion];
      }
  
      // Place the new champion into the destination frame
      roleChampions[destination.droppableId] = [draggableId];
  
      // Remove the new champion from the pool
      roleChampions.pool = roleChampions.pool.filter(id => id !== draggableId);

      updateTierlistInDB(currentRole, roleChampions);
      return updatedChampions;
    });
  };
  
  
  
  useEffect(() => {

      axiosInstance.get('champion_roles/')
      .then(response => {
        const { columns, data } = response.data;

        if (Array.isArray(columns) && Array.isArray(data)) {
          const classNames = columns;
          const championClassMap = {};

          data.forEach(({ championId, className }) => {
            if (!championClassMap[championId]) {
              championClassMap[championId] = [];
            }
            championClassMap[championId].push(className);
          });

          setChampionTags(championClassMap);
          setClasses(classNames);
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching roles and classes:', error);
      });

      axiosInstance.get('tierlistgrind/?teamName=' + localStorage.getItem("team"))
      .then(response => {
        if (Array.isArray(response.data)) {
          const roleFrames = {}; // Store frames for each role
    
          response.data.forEach(item => {
            const role = item.role;
            roleFrames[role] = {
              "Frame 1": [item["F1"]],
              "Frame 2": [item["F2"]],
              "Frame 3": [item["F3"]],
              "Frame 4": [item["F4"]],
              "Frame 5": [item["F5"]],
              "Frame 6": [item["F6"]],
              "Frame 7": [item["F7"]],
              "Frame 8": [item["F8"]],
            };
          });
          
          setChampions(prevChampions => {
            const updatedChampions = rolesPublic.reduce((acc, role) => {
              const roleData = roleFrames[role] || {}; // Fetch role frames, default to empty object
          
              // If roleData is empty, ensure the structure still gets set as expected
              const frames = Object.keys(roleData).length > 0
                ? Object.fromEntries(
                    Object.keys(roleData).map(frameKey => [frameKey, roleData[frameKey]])
                  )
                : {};
          
              // Exclude champions in the frames from the pool
              const frameChampions = Object.values(frames).flat(); // Flatten the array of champions in frames
              const updatedPool = initialChampions.filter(champion => !frameChampions.includes(champion));
          
              // Set the updated champions state
              acc[role] = {
                pool: updatedPool, // Filtered pool to exclude champions already in frames
                ...frames // Add the frames data (or empty object if no data)
              };
          
              return acc;
            }, {});
          
            return updatedChampions;
          });
          
        }
      })
      .catch(error => {
        console.error('Error fetching tier list:', error);
      });
    
  }, []);
  
  const updateTierlistInDB = (role, updatedData) => {
    const roleData = {
      teamName: localStorage.getItem("team"), // Use teamName
      role: role,  // Use the role to identify the entry
      F1: updatedData["Frame 1"][0],
      F2: updatedData["Frame 2"][0],
      F3: updatedData["Frame 3"][0],
      F4: updatedData["Frame 4"][0],
      F5: updatedData["Frame 5"][0],
      F6: updatedData["Frame 6"][0],
      F7: updatedData["Frame 7"][0],
      F8: updatedData["Frame 8"][0],
    };
  
    // Send an array of updated data
    axiosInstance.post('tierlistgrind/', [roleData]) // Wrap the data in an array
      .then(response => {
        toast.success("Tier list updated!");
      })
      .catch(error => {
        console.error(`Error updating tier list for ${role}:`, error);
        toast.error("Failed to update tier list.");
      });
  };
  

  const handleTagSelection = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleScoutingClick = () => {
    window.location.href = `/ChampionRole`;
  };

  const handleRoleChange = (role) => {
    setCurrentRole(role);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const renderTierList = (role) => {
    return (
      <div style={{width: showCombined ? "20%": "100%" }}>
        <div style={{marginBottom: "10px", display: 'flex', width: "100%", height: showCombined ?  "90px": "170px" , backgroundColor: 'rgb(255, 127, 128)' }} key={`${role}-row1`} className="frame-row">
          {((showCombined && role==="top") ||  !showCombined) && (
          <div style={{ display: 'flex', justifyContent: 'space-around', width: "5%" }}>
            <h1 style={{ fontSize: showCombined ?  "70px" :"140px" }}>S</h1>
          </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-around', width:  (!showCombined || role==="top") ? "95%" : "100%", alignItems: 'center' }}>
            {frames.row1.map(frame => (
            <Droppable key={frame} droppableId={frame} direction="horizontal">
                {(provided) => (
                  <div style={{ backgroundColor: "white", width: showCombined ? "70px": "150px", height:  showCombined ? "70px": "150px"}} ref={provided.innerRef} {...provided.droppableProps} className="frame">
                    {champions[role][frame].slice(0, 1).map((championId, index) => (
                      <Draggable key={championId} draggableId={championId} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="champion"
                            style={{ width: showCombined ? "70px": "150px",height:  showCombined ? "70px": "150px"}}
                          >
                            <img style={{ width:  showCombined ? "70px": "150px", height:  showCombined ? "70px": "150px" }} src={championPhotos[championData[championId]]} alt={championId} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </div>
  
  
        <div style={{ display: 'flex', width: "100%", height: showCombined ? "60px": "120px" , backgroundColor: 'rgb(204, 255, 102)' }} key={`${role}-row2`} className="frame-row">
        {((showCombined && role==="top") ||  !showCombined) && (
          <div style={{ display: 'flex', justifyContent: 'space-around', width: "5%", alignItems: "center" }}>
            <h1 style={{ fontSize: showCombined ?  "40px" :"90px" }}>A</h1>
          </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-around', width:  (!showCombined || role==="top") ?  "95%" : "100%", alignItems: 'center' }}>
            {frames.row2.map(frame => (
            <Droppable key={frame} droppableId={frame} direction="horizontal">
                {(provided) => (
                  <div style={{ backgroundColor: "white", width:showCombined ? "45px": "100px", height: showCombined ? "45px": "100px" }} ref={provided.innerRef} {...provided.droppableProps} className="frame">
                    {champions[role][frame].slice(0, 1).map((championId, index) => (
                      <Draggable key={championId} draggableId={championId} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="champion"
                            style={{ width: showCombined ? "45px": "100px", height: showCombined ? "45px": "100px"}}
                          >
                            <img style={{ width: showCombined ? "45px": "100px", height: showCombined ? "45px": "100px"}} src={championPhotos[championData[championId]]} alt={championId} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className="tierlist-container">
      <div className="role-selector">
        {rolesPublic.map((role, index) => (
        <button
          key={role}
          onClick={() => handleRoleChange(role)}
          style={{ width: (100 / 5) + "%" }}
          className={currentRole === role ? 'button-top-page-active' :'button-top-page'}
        >
          <img className="small-image" src={roleIcon[index]} alt=""/>
          {role.toUpperCase()}
        </button>
        ))}

      </div>
      <div style={{height: "50px"}}>

      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{display: "flex", width: "100%"}}>
          {showCombined ? rolesPublic.map(role => renderTierList(role)) : renderTierList(currentRole)}
        </div>

        <div style={{height: "50px"}}>

        </div>

        <div style={{color:"white", width: "100%", display: 'flex', justifyContent: 'space-around'}}>
          <div style={{display: 'flex', width: "20%", flexDirection: 'column', justifyContent: 'space-around'}}>
            <input
              type="text"
              placeholder="Filter champion name"
              value={searchTerm}
              onChange={handleSearchChange}
              />
            <button className='button-top-page' onClick={() => handleScoutingClick()}>Change Roles</button>
            <button className="button-top-page" onClick={toggleCombinedView}>
              {showCombined ? "Single" : "Combined"}
            </button>
          </div>
          <div style={{width: "70%"}}>
            <div style={{display: 'flex'}}>
              {classes.slice(0,5).map((tag, id) => (
                <button
                  key={tag}
                  onClick={() => handleTagSelection(tag)}
                  className={selectedTags.includes(tag) ? 'button-top-page-active' :'button-top-page'}
                  style={{width: "20%", display: 'flex', justifyContent: "center"}}
                >
                  <img className="small-image" src={roleIcon[id]} alt={id}/>
                  {tag}
                </button>
              ))}
            </div>
            <div style={{display: "flex", fontSize: "10px", textAlign: "center"}}>
              {classes.slice(5).sort().map(tag => (
                <div style={{display: 'flex', width: `${100 / (classes.slice(5).length || 1)}%`}}>
                  <button
                    key={tag}
                    onClick={() => handleTagSelection(tag)}
                    className={selectedTags.includes(tag) ? 'button-top-page-active' :'button-top-page'}
                    style={{width: "100%", display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center"}}
                    >
                    <img className="small-image" src={classesPhotos[tag]} alt={tag}/>
                    {tag}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>


        <Droppable droppableId="pool" direction="horizontal">
          {(provided) => {
              const filteredItems = champions[currentRole].pool.filter(championId => {
                const championName = championData[championId]?.toLowerCase();
                const matchesSearchTerm = championName.includes(searchTerm.toLowerCase());
                const matchesTags = selectedTags.length === 0 || selectedTags.every(tag => championTags[championId]?.includes(tag));
            
                return matchesSearchTerm && matchesTags;
              });
              return (

                <div ref={provided.innerRef} {...provided.droppableProps} className="pool">
              {sortChampionIds(filteredItems).map((championId, index) => (
                <Draggable key={championId} draggableId={championId} index={index}>
                  {(provided) => (
                    <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="champion"
                    >
                      <img style={{ width: "50px" }} src={championPhotos[championData[championId]]} alt={championId} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
        )}}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default TierlistCustomFrames;
