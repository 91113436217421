import React, { useEffect, useState } from 'react';
import { useSeason } from '../SeasonContext';
import LoadingComponent from '../base/LoadingComponent';
import axiosInstance from '../backend/axiosInstance';
import Slider from 'rc-slider';
import mapPhotsDictionary from '../data/MapData';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';
import { TeamIcons } from '../data/TeamIcons';
import { championPhotos } from '../data/ChampionsIcon';
import { handleVODClick } from '../base/base';
import ReactPlayer from 'react-player';

const PlayerWeaksideKingComponent = ({ name }) => {
  const { currentSeasonTime } = useSeason();
  const [timeRange, setTimeRange] = useState([0, 60]);
  const [selectedTeam, setSelectedTeam] = useState('any');
  document.title = "Weakside King " + name;
  const encodedPlayerName = encodeURIComponent(name);
  const [teleportData, setTeleportData] = useState([]);


  const [playerPositionData, setPlayerPositionData] = useState([]);
  const [selectedPlayerPositionData, setSelectedPlayerPositionData] = useState(null);
 
  const [matchData, setMatchData] = useState([]);
  const [filteredTeleportData, setFilteredTeleportData] = useState([]);

  const [selectedGameId, setSelectedGameId] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState("");

  const minX = -120;
  const minY = -120;
  const maxX = 14970;
  const maxY = 14980;

  const captureScreenshot = (table) => {
      const element = document.getElementById(table);

      html2canvas(element)
          .then((canvas) => {
              const image = canvas.toDataURL('image/png');
              const link = document.createElement('a');
              link.download = 'screenshot.png';
              link.href = image;
              link.click();
          })
          .catch(err => {
              console.error('Error capturing screenshot', err);
          });
  };

  const translateCoordinates = (x, y) => {
    const newX = ((x - minX) / (maxX - minX)) * 500;
    const newY = ((maxY - y) / (maxY - minY)) * 500;
    return { newX, newY };
  };

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());

    axiosInstance.get(`player/weaksideking/?name=${encodedPlayerName}&start=${start}&end=${end}`)
      .then((response) => {

        const updatedData = response.data.map((item) => {
          try {
            if (item.Vod.includes('t=')) {
              // Replace &amp; with & before parsing
              const fixedVod = item.Vod.replace(/&amp;/g, '&');
              const [baseUrl, queryString] = fixedVod.split('?');
              const params = new URLSearchParams(queryString);
              
              let timestamp = params.get('t'); // Extract timestamp
        
              if (timestamp && !isNaN(timestamp) && typeof item.time === 'number') {
                return { ...item, Vod: `${baseUrl}?t=${Number(timestamp) + item.time - 30}` };
              }
              return { ...item, Vod: baseUrl }; 
            }
          } catch (error) {
            console.error('Error parsing timestamp:', error, item.Vod);
          }
          return item;
        });
        
        setTeleportData(updatedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [currentSeasonTime, encodedPlayerName]);

  useEffect(() => {
    let filteredData = teleportData

    filteredData = filteredData.filter(ward => ward.time >= timeRange[0] * 60 && ward.time <= timeRange[1] * 60);

    if (selectedTeam !== 'any') {
      filteredData = filteredData.filter(ward => 
        ward.positions && Array.isArray(ward.positions) && ward.positions.length > 0 && ward.positions[0].side === selectedTeam
      );
    }

    setFilteredTeleportData(filteredData);
  }, [teleportData, selectedTeam, timeRange]);

  const handleTimeRangeChange = (newTimeRange) => {
    setTimeRange(newTimeRange);
  };

  const fetchData = (gameId, time) => {
    axiosInstance.get(`playerposition/?gameId=${gameId}&time=${time}`)
      .then((response) => {
        setPlayerPositionData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    axiosInstance.get(`parsed_official_games/?gameId=${gameId}`)
      .then((response) => {
        setMatchData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  return (
    <div>
      <div>
        {teleportData? (
          <>
            <div style={{ width: "100%", color: "white", textAlign: "center", alignItems: "center" }}>
              <h1>Weakside King</h1>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: "100%", justifyContent: 'space-evenly' }}>
                <div>
                  <label htmlFor="teamSelect">Select side:</label>
                  <select id="teamSelect" value={selectedTeam} onChange={e => setSelectedTeam(e.target.value)}>
                    <option value="any">Any</option>
                    <option value="blue">Blue</option>
                    <option value="red">Red</option>
                  </select>
                </div>
              </div>
              <div style={{ width: "100%", display:'flex', justifyContent: "center", paddingBottom: "20px"}}>
                <div style={{ width: "80%"}}>
                  <span>{timeRange[0]} - {timeRange[1]}</span>
                  <Slider
                    range
                    min={0}
                    max={60}
                    step={1}
                    value={timeRange}
                    onChange={handleTimeRangeChange}
                    marks={{
                      0: "0",
                      15: "15",
                      30: "30",
                      45: "45",
                      60: "60",
                    }}
                    style={{
                      color: 'white',
                      width: '100%',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      background: 'darkblue',
                      border: 'none',
                      borderRadius: '5px',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    }}

                    />
                </div>
              </div>
              {selectedVideo !== "" && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "500px" }}>
                    <div style={{ width: "80%", height: "100%" }}>
                      <ReactPlayer
                        url={selectedVideo}
                        width="100%"
                        height="100%"
                        controls={true}
                      />
                    </div>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <div id="heatmap-container-2" style={{ position: 'relative' }}>
                          <img style={{ position: 'relative' }} src={mapPhotsDictionary["SummonersRift"]} alt="" />
                          {filteredTeleportData && filteredTeleportData.map((tp, index) => {
                            const { newX, newY } = translateCoordinates(tp.positions[0].x, tp.positions[0].y);

                            return (
                              <div key={index}>
                                  <div
                                      style={{
                                          position: 'absolute',
                                          top: `${newY}px`,
                                          left: `${newX}px`,
                                          color: 'red',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          zIndex: 3,
                                          pointerEvents: 'none',
                                          opacity: selectedGameId !==null ? (selectedGameId === tp.id ? 1 : 0.1) : 1,
                                        }}
                                  >
                                      X
                                  </div>
                              </div>
                          );
                          })}
                          {selectedPlayerPositionData && (
                            <div
                                key='timer'
                                style={{
                                position: 'absolute',
                                top:  0,
                                left: "45%",
                                color: 'white'
                                }}>
                                {Math.floor(selectedPlayerPositionData.time / 60)}:{String(selectedPlayerPositionData.time % 60).padStart(2, '0')}
                            </div>
                          )}
                          {matchData && (
                              <div
                                  key='team1'
                                  style={{
                                  position: 'absolute',
                                  top:  0,
                                  left: "20%",
                                  color: 'blue',
                                  fontWeight: matchData.team1,

                                }}>
                                  <img src={TeamIcons[matchData.team1]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                                  {matchData.team1}
                              </div>
                          )}
                          {matchData && (
                              <div
                                  key='team2'
                                  style={{
                                  position: 'absolute',
                                  top:  0,
                                  left: "70%",
                                  color: 'red',
                                  fontWeight: matchData.team2,
                                  }}>
                                  <img src={TeamIcons[matchData.team2]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                                  {matchData.team2}
                              </div>
                          )}
                          {playerPositionData && playerPositionData.map((position, index) => {
                              const { newX, newY } = translateCoordinates(position.x, position.y);
                              return (
                                  <div
                                  key={`player--${index}-t`}
                                  style={{
                                      position: 'absolute',
                                      top: `${newY - 13}px`,
                                      left: `${newX - 13}px`,
                                      width: '26px',
                                      height: '26px',
                                      backgroundColor: position.summonerName===name ? "yellow" : position.side === "blue" ? "blue":"red",
                                      zIndex: position.summonerName===name ? 10 : 1,
                                      borderRadius: '50%',
                                  }}
                                  >

                                      <div
                                          key={`player--${index}`}
                                          style={{
                                              position: 'absolute',
                                              top: `2px`,
                                              left: `2px`,
                                              width: '22px',
                                              height: '22px',
                                              borderRadius: '50%',
                                          }}
                                          >

                                          <img
                                              src={championPhotos[position.championName]}
                                              alt=""
                                              style={{
                                                  width: '20px',
                                                  height: '20px',
                                                  position: 'absolute',
                                                  borderRadius: '50%',
                                                  top: '1px',
                                                  left: '1px',
                                              }}
                                              />
                                      </div>
                                  </div>
                              );
                              }
                          )}

                      </div>
                  <button style={{ width: "100%" }} className='button-top-page' onClick={() => captureScreenshot('heatmap-container-2')}>ScreenShot</button>
                </div>
                <div style={{ overflowY: 'scroll', height: '500px', width: '60%' }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead style={{position: "sticky", backgroundColor: "#222", zIndex: "2", top: "0"}}>
                      <tr>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}></th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Time</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Player</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Game</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}></th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Type</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>vs</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>CS</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Enemy Flash</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Flash</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>Kills</th>
                        <th style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>VOD</th>

                      </tr>
                    </thead>
                    <tbody>
                    {filteredTeleportData.sort((a,b)=> a.gameId - b.gameId).map((ward, index) => (
                        <tr
                        key={ward.id}
                        style={{
                          backgroundColor: selectedGameId === ward.id ? 'gray' : 'transparent',
                          cursor: 'pointer'
                        }}
                      >
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedGameId === ward.id} // Ensure it matches gameId
                              onChange={(e) => {
                                if (e.target.checked) {
                                  fetchData(ward.gameId, ward.time);
                                  setSelectedGameId(ward.id); // Store gameId
                                  setSelectedPlayerPositionData(ward)
                                  setSelectedVideo(ward.Vod !== null ? ward.Vod : "")
                                } else {
                                  setSelectedGameId(null);
                                  setSelectedPlayerPositionData(null)

                                  setPlayerPositionData([]); // Reset data when unchecked\
                                  setSelectedVideo("");
                                }
                              }}
                            />
                          </td>
                          <td style={{ padding: '10px' }}>
                            {Math.floor(ward.time / 60)}:{String(ward.time % 60).padStart(2, '0')}
                          </td>
                          <td style={{ padding: '10px' }}>{ward.summonerName}</td>
                          <td style={{ padding: '10px' }}>{ward.team1} vs {ward.team2}</td>
                          <td style={{ padding: '10px' }}>Game {ward.gameId.slice(-2, -1) === "_" ? ward.gameId.slice(-1): "1" }</td>
                          <td style={{ padding: '10px' }}>{ward.type}</td>
                          <td style={{ padding: '10px' }}>{ward.versusEnemyNumber}</td>
                          <td style={{ padding: '10px' }}>{ward.CSBeforeDeath}</td>
                          <td style={{ padding: '10px' }}>{ward.enemyFlash}</td>
                          <td style={{ padding: '10px' }}>{ward.playerFlash}</td>
                          <td style={{ padding: '10px' }}>{ward.playerKills}</td>
                          <td style={{ padding: '10px' }}> {ward.Vod && <button className='button-match-history' onClick={() => handleVODClick(ward.Vod)}>VOD</button>}</td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  );
};

export default PlayerWeaksideKingComponent;
