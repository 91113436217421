import role0Icon from '../../role_photos/0.png';
import role1Icon from '../../role_photos/1.png';
import role2Icon from '../../role_photos/2.png';
import role3Icon from '../../role_photos/3.png';
import role4Icon from '../../role_photos/4.png';

import D0 from '../../role_photos/dark_TOP2.png';
import D1 from '../../role_photos/dark_JNG2.png';
import D2 from '../../role_photos/dark_MID2.png';
import D3 from '../../role_photos/dark_BOT2.png';
import D4 from '../../role_photos/dark_SUP2.png';

const roleIcon = {
  0: role0Icon,
  1: role1Icon,
  2: role2Icon,
  3: role3Icon,
  4: role4Icon,
  D0, D1, D2, D3, D4
};

export default roleIcon;